body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.no-data {
  color: #25242F;
  font-family: "Orelega One";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.coming-soon {
  color: #25242F;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-align: center;
}
