.popup-overlay-CancelSubscription {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container-CancelSubscription {
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 32px 40px 32px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 450px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.icon-container-CancelSubscription {
  margin-bottom: 15px;
}

.card-icon-CancelSubscription {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #2ecc71;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.popup-container-CancelSubscription .card-add-popup-main-CancelSubscription {
  margin-bottom: 10px;
  color: #25242f;
  text-align: center;
  font-family: "Orelega One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
}

.popup-container-CancelSubscription .CancelSubscription-popup-sub {
  margin-bottom: 20px;
  color: #7d7d7d;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.popup-container-CancelSubscription .CancelSubscription-ok-button {
  display: flex;
  width: 295px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #9d9d9d;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
}

.popup-container-CancelSubscription .CancelSubscription-cancel-button {
  display: flex;
  width: 295px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #f65c5c;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
}

.CancelSubscription-button-main {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.popup-container-CancelSubscription .CancelSubscriptionSuccess-ok-button {
  display: flex;
  width: 175px;
  height: 32px;
  padding: 5px 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #687362;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  text-transform: capitalize;
  border: none;
}
.CancelSubscriptionSuccess-button-main{
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .CancelSubscription-button-main {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
  }
  .popup-container-CancelSubscription {
    background-color: #fff;
    border-radius: 10px;
    padding: 24px 32px 40px 32px;
    width: 279px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  .question .UnsubscribePopup-popup-pickup-option-container {
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }
  .UnsubscribePopup-popup-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 55%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UnsubscribePopup-popup-container {
    position: fixed;
    top: 100px !important;
    left: 16px;
    width: 92%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
  }
  .popup {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    overflow-y: scroll;
  }
  .CancelSubscription-button-main {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  .CancelSubscriptionSuccess-button-main{
    display: flex;
    justify-content: center;
  }
}
