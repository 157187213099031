.section-2-howto{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 66px 0px;

}
.special-wrapper-howto{
  background: #F8F7F2;
}
.how-sec2-div1{
  width: 70%;
}
.how-sec2-div2{
  width: 41%;
}
.section-2-howto .topic{
  color: #25242F;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  text-align: center;
  display: block;
}
.section-2-howto .description{
  color: #7D7D7D;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  width: 80%;
  line-height: 28px; /* 140% */
}


.wrapper-howto-3{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  background: #687362;
  height: 570px;
}
.wrapper-howto-3 img{
  height: 571px;
  object-fit: cover;
  width: 100%;
}
.section-3-howto-image{
  width: 50%;
}
.section-3-howto-content{
  padding-left: 164px;
  padding-right: 116px;
  width: 52%;
}
.section-3-howto-content h2{
  color: #FFF;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 128.125% */
  margin: 0;
  padding-bottom: 40px;
}
.section-3-howto-content p{
  margin: 0;
  color: #FFF;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 40px;
  line-height: 24px; /* 150% */
}
.section-3-howto-content .button-hm-3{
  display: flex;
  width: 150px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #FFF;
  gap: 10px;
  border: none;
  color: #687362;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  text-decoration: none;
}
.howto-sec-4-main{
  padding: 80px 0px;
}
.sec-4-howto-topic{
  color: #25242F;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 128.125% */
  text-transform: capitalize;
  display: block;
  margin-bottom: 60px;
  text-align: center;
}
.step-guide{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.howto-sec-4-main .single-flex-rw{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 80px;
  width: 80%;
  margin: 0 auto;

}
.howto-sec-4-main .single-flex-rw img{
  width: 271px;
  height: 193px;
  object-fit: cover;
  border-radius: 12px;
  border: 0.5px solid #d5d3c6;
}
.text-sec-4-howto{
  display: flex;
  flex-direction: column;
  width: 80%;
}
.text-sec-4-howto .sub-title{
  margin-bottom: 12px;
  color: #7D7D7D;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 171.429% */
}
.text-sec-4-howto .sub-title2{
  margin-bottom: 26px;
  color: #25242F;
  font-family: "Orelega One";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}
.text-sec-4-howto .sub-title3{
  color: #A6A6A6;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px; /* 137.5% */
}
.special-wrapper2-howto{
  background: #687362;
}
.section-5-howto{
  padding: 80px 0px;
}
.section-5-howto .faq-item .faq-question{
  padding: 40px 0px;
  border-top: 1px solid #D6D6D6;
  border-bottom: 0px solid #D6D6D6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 128.125% */
  text-transform: capitalize;
  cursor: pointer;
}
.section-5-howto .faq-item:last-child .faq-question{
  padding: 40px 0px;
  border-top: 1px solid #D6D6D6;
  border-bottom: 0px solid #D6D6D6;
}
.section-5-howto .faq-answer{
  color: #FFF;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding-bottom: 40px;
  border-top: 1px solid #D6D6D6 ;
  padding-top: 40px;
}


@media (max-width:678px){
  .section-2-howto{
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: space-between;
    padding: 66px 0px;
  
  }
  .special-wrapper-howto{
    background: #F8F7F2;
  }
  .how-sec2-div1{
    width: 100%;
  }
  .how-sec2-div2{
    width: 100%;
  }
  .section-2-howto .topic{
    color: #25242F;
    font-family: "Orelega One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 115.385% */
    margin-bottom: 56px;
    display: block;
  }
  .section-2-howto .description{
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    width: 100%;
  }
  
  
  .wrapper-howto-3{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    background: #687362;
    height: unset;
    padding: 0px 0px;
  }
  .wrapper-howto-3 img{
    height: unset;
    object-fit: cover;
    width: 100%;
  }
  .section-3-howto-image{
    width:100%;
  }
  .section-3-howto-content{
    padding-left: 0px;
    padding-right: 0px;
    padding: 60px 0px;
    width: 85%;
  }
  .section-3-howto-content h2{
    color: #FFF;
    font-family: "Orelega One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 115.385% */
    text-transform: capitalize;
    padding-bottom:20px;
  }
  .section-3-howto-content p{
    margin: 0;
    color: #FFF;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding-bottom: 0px;
  }
  .section-3-howto-content .button-hm-3{
    display: flex;
    width: 150px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #FFF;
    gap: 10px;
    border: none;
    color: #687362;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-decoration: none;
  }
  .howto-sec-4-main{
    padding: 80px 0px;
  }
  .sec-4-howto-topic{
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px; /* 128.125% */
    text-transform: capitalize;
    display: block;
    margin-bottom: 60px;
    text-align: center;
  }
  .step-guide{
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  .howto-sec-4-main .single-flex-rw{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 24px;
    width: 80%;
    margin: 0 auto;
  
  }
  .howto-sec-4-main .single-flex-rw img{
    width: 100%;
    height: 193px;
    object-fit: cover;
    border-radius: 12px;
  }
  .text-sec-4-howto{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .text-sec-4-howto .sub-title{
    margin-bottom: 12px;
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 123.077% */
  }
  .text-sec-4-howto .sub-title2{
    margin-bottom: 26px;
    color: #25242F;
    font-family: "Orelega One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
  .text-sec-4-howto .sub-title3{
    color: #A6A6A6;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; /* 137.5% */
  }
  .special-wrapper2-howto{
    background: #687362;
  }
  .section-5-howto{
    padding: 60px 0px;
  }
  .section-5-howto .faq-item .faq-question{
    padding: 20px 0px;
    border-top: 1px solid #D6D6D6;
    border-bottom: 0px solid #D6D6D6;
    display: flex;
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 115.385% */
    text-transform: capitalize;
    width: 100%;
    width: 85%;
    margin: 0 auto;
  }
  .section-5-howto .faq-item:last-child .faq-question{
    padding: 20px 0px;
    border-top: 1px solid #D6D6D6;
    border-bottom: 0px solid #D6D6D6;
  }
  .section-5-howto .faq-answer{
    color: #FFF;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    padding-bottom: 40px;
    border-top: 1px solid #D6D6D6 ;
    padding-top: 20px;
    width: 85%;
    margin: 0 auto;
  }
  .spc-howto-img-height img{
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 200px;
    width: 100%;
  }
}

@media(min-width:1200px){
  .section-3-howto-image{
    width: 50%;
    opacity: 0;
  }
  .section-3-howto-content {
    padding-left: 0;
    padding-right: 150px;
    width: 52%;
  }
  .wrapper-howto-3 {
    height: 554px;
  }
  .how-common-wrapper .child-inner-div{
    border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
  }
  .how-common-wrapper .child-inner-div img {
    width: 100%;
    min-height: 500px;
    object-fit: cover;
    margin: 0;
    margin-bottom: -4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  } 
  .how-common-wrapper .inner-cont {
    display: flex;
    gap: 60px;
    align-items: center;
    background: #687362;
    margin: 100px 0px;
    border-radius: 12px;
    flex-direction: row-reverse;
  }
  .how-common-wrapper .section-3-howto-content p {
    margin: 0;
    color: #FFF;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 0;
    line-height: 24px;
  }
  .how-common-wrapper .section-3-howto-content {
    padding-left: 0;
    padding-left: 50px;
    padding-right: 0;
    width: 55%;
  }
  .text-sec-4-howto {
    display: flex;
    flex-direction: column;
    width: 37%;
  }
  .howto-sec-4-main .single-flex-rw {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 80px;
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 678px) {
  .how-common-wrapper .section-3-howto-content {
      padding-left: 0px;
      padding-right: 0px;
      padding: 10px 0px 50px;
      width: 85%;
  }
}