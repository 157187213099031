.profile-img {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 40%;
}

.profile-cover {
  height: 200px;
  background: linear-gradient(135deg, #9bb7c5 0%, #527a6a 100%);
}

.section {
  margin: 24px;
}

.section-two {
  margin: 15% 24px 24px 24px;
}

.tab-selections {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  width: 100%;
  width: 80%;
  margin: 0 auto;
}

.tab-selections option {
  color: #7d7d7d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.tab-container {
  padding: 20px 0px;
}

.section-title {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 115.385% */
  text-transform: capitalize;
}

.section-sub-title {
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.profile-section-wrap {
  background: #f8f7f2;
  margin: 0;
}

.mobile-only {
  display: block;
}

.desktop-only {
  display: none;
}

.card-info {
  display: flex;
  align-items: center;
  border-top: 1px solid #cccccc;
  width: 89%;
  margin-top: 27px;
  gap: 10px;
}

.card-info p {
  display: flex;
  align-items: center;
  gap: 5px;
}

.course-card-image img {
  width: 264px;
  height: 209px;
  border-radius: 6px;
  object-fit: cover;
}

.course-type-title {
  color: #25242f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Orelega One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  text-transform: capitalize;
  margin-top: -10%;
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.popup-actions {
  margin-top: 20px;
  display: flex;
}

.plan-confirm-btn {
  background: #333333;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  width: 50%;
}

.plan-cancel-btn {
  background: #ffffff;
  color: rgb(0, 0, 0);
  border: 1px #333333;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  width: 50%;
}

@media (min-width: 1200px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: flex;
    gap: 25px;
  }

  .profile-img {
    margin-top: 10%;
    width: 188px;
    height: 188px;
  }

  .section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .section-two {
    width: auto;
    max-width: 1200px;
    margin: 0% 26%;
  }

  .section-title {
    color: #25242f;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 115.385% */
    text-transform: capitalize;
    margin: 0;
    padding-top: 20px;
  }

  .section-sub-title {
    color: #7d7d7d;
    font-family: "Public Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .tab-container {
    width: 100%;
    max-width: 1200px;
    margin: 3% auto;
  }

  .tab-item {
    color: #25242f;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: capitalize;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .tab-container .tab-item.active {
    color: #687362;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: capitalize;
    position: relative;
  }

  .tab-container .tab-item.active::after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 50%;
    height: 2px;
    width: 20px;
    transform: translateX(-50%);
    background: #687362;
    border-radius: 50%;
  }

  .card-info {
    display: flex;
    align-items: center;
  }

  .course-card-image img {
    width: 264px;
    height: 209px;
    border-radius: 6px;
    object-fit: cover;
  }

  .course-type-title {
    color: #25242f;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 145.455% */
    text-transform: capitalize;
    margin-top: -10%;
  }

  .render-content {
    margin-top: -60px;
  }
}

@media (max-width: 678px) {
  .section-two {
    margin-top: 0;
  }

  .section-title {
    margin-top: 0;
    padding-top: 60px;
  }

  .render-content {
    margin-top: -60px;
  }
}

/* user billing part css below */

.input-mail-logo-wrapper {
  display: flex;
  width: 400px;
}

.subscription-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  border-bottom: 1px solid #d6d6d6;
}

.alert-box {
  background-color: #fff5f5;
  color: #f65c5c;
  border: 1px solid #f65c5c;
  padding: 5px 17px;
  border-radius: 50px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-family: "Orelega One";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 82%;
}

.alert-icon {
  font-size: 1.5em;
  margin-right: 10px;
}

.current-plan-box {
  background-color: #f8f7f2;
  border: 1px solid #cfc8a8;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}

.current-plan-box .plan-details-price {
  display: flex;
  justify-content: space-between;
}

.current-plan-box .plan-details {
  width: 60%;
}

.current-plan-box .switch-plan-main {
  display: flex;
  gap: 10px;
  color: #25242f;
  font-family: "Orelega One";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.current-plan-box > .switch-plan-main > label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan-details h4 {
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
}

.plan-details p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: #6c757d;
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.plan-price {
  font-size: 1.5em;
  font-weight: bold;
  color: #343a40;
  text-align: right;
  color: #25242f;
  font-family: "Orelega One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.plan-price span:last-child {
  font-size: 0.8em;
  font-weight: normal;
  color: #6c757d;
  color: #9d9d9d;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}

.switch-plan {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.switch-plan input[type="checkbox"] {
  margin-right: 10px;
}

.billing-common-right .billing-page-sub-btn-main {
  display: flex;
  gap: 10px;
}

.billing-common-right .renew-button {
  background-color: #687362;
  color: #fff;
  border: none;
  padding: 5px 17px;
  font-size: 1em;
  border-radius: 50px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-family: "Moranga";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  width: 210px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Orelega One", serif;
  font-weight: 400;
  font-style: normal;
  border-radius: 50px;
  border: 2px solid;
}

.billing-common-right .cancel-button {
  border-radius: 50px;
  border: 1px solid #687362;
  color: #687362;
  text-align: center;
  font-family: "Orelega One";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  display: flex;
  padding: 5px 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.renew-button:hover {
  background-color: #4e5d4d;
}

.subscription-container-sub {
  display: flex;
  gap: 300px;
  margin: 20px 0px;
}

.subscription-container-sub .section-1-subscription {
  width: 20%;
}

.contact-email-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  max-width: 600px;
}

.contact-email-container h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.contact-email-container p {
  margin: 5px 0 20px;
  color: #6c757d;
}

.email-option {
  display: flex;
  margin-bottom: 2px;
  align-items: flex-start;
}

.email-option input[type="radio"] {
  margin-right: 10px;
}

.email-option label {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.email-option label span {
  font-size: 1em;
  color: #333;
}

.email-option .email-display {
  font-size: 0.9em;
  color: #6c757d;
}

.alternate-email-input {
  width: 100%;
  padding: 12px 24px;
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 120px;
  font-size: 1em;
  color: #fff;
}

.alternate-email-input::placeholder {
  color: #adb5bd;
}

.alternate-email-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.billing-common-right .card-info {
  border-radius: 8px;
  border: 1px solid #cfc8a8;
  background: none;
  display: flex;
  width: 708px;
  padding: 24px;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin: 15px 0px;
}

.card-info.selected {
  background-color: #f8f7f2;
}

.billing-common-right .card-info-sub {
  display: flex;
  width: 708px;
  justify-content: space-between;
}

.billing-common-right .card-details-main {
  display: flex;
  gap: 60px;
}

.billing-common-right .add-method-btn {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: none;
  border: none;
}

.billing-common-right .card-details .remove-btn {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: none;
  border: none;
  padding: 0px;
}

.billing-common-right .card-details .edit-btn {
  color: #9d9d9d;
  font-family: "Orelega One";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: none;
  border: none;
}

.subscription-container-sub .subscription-main-name {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
}

.subscription-container-sub .subscription-sub-name {
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.custom-radio input {
  opacity: 0;
  position: absolute;
}

.custom-radio {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.custom-radio .custom-radio-checkmark {
  width: 18px;
  height: 18px;
  background-color: none;
  border-radius: 50%;
  border: 1px solid #cfc8a8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.custom-radio .custom-radio-checkmark::after {
  content: "";
  display: none;
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4IiBmaWxsPSJub25lIj48cGF0aCBkPSJNOSAxLjVDNC44Njc1IDEuNSAxLjUgNC44Njc1IDEuNSA5QzEuNSAxMy4xMzI1IDQuODY3NSAxNi41IDkgMTYuNUMxMy4xMzI1IDE2LjUgMTYuNSAxMy4xMzI1IDE2LjUgOUMxNi41IDQuODY3NSAxMy4xMzI1IDEuNSA5IDEuNU0xMi41ODUgNy4yNzVMOC4zMzI1IDExLjUyNzVDOC4yMjc1IDExLjYzMjUgOC4wODUgMTEuNjkyNSA3LjkzNSAxMS42OTI1QzcuNzg1IDExLjY5MjUgNy42NDI1IDExLjYzMjUgNy41Mzc1IDExLjUyNzVMNS40MTUgOS40MDVDNS4xOTc1IDkuMTg3NSA1LjE5NzUgOC44Mjc1IDUuNDE1IDguNjAwQzUuNjMyNSA4LjM5MjUgNS45OTI1IDguMzkyNSA2LjIxMCA4LjYxMEw3LjkzNSAxMC4zMzVMMTEuNzkgNi40OEMxMi4wMDc1IDYuMjYyNSAxMi4zNjc1IDYuMjYyNSAxMi41ODUgNi40OEMxMi44MDI1IDYuNjk3NSAxMi44MDI1IDcuMDUgMTIuNTg1IDcuMjc1WiIgZmlsbD0iIzI1MjQyRiIvPjwvc3ZnPg==");
  background-size: contain;
  border: none;
}

.custom-radio input:checked + .custom-radio-checkmark::after {
  display: block;
}

.custom-radio-email input[type="radio"] {
  opacity: 0;
  position: absolute;
}

.custom-radio-email {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

.custom-radio-email .custom-radio-checkmark-email {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: rgb(247, 243, 243);
  border-radius: 50%;
  border: 1px solid black;
}

.custom-radio-email input:checked + .custom-radio-checkmark-email {
  background-color: white;
  border: 1px solid black;
  margin-top: 0px;
}

.custom-radio-email .custom-radio-checkmark-email::after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-email input:checked + .custom-radio-checkmark-email::after {
  display: block;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(9, 9, 9);
}

.billing-common-right .input-mail-logo {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  width: 100%;
}

.billing-email {
  border: none;
  color: #070707;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.billing-email::placeholder {
  border: none;
  color: #9d9d9d;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.email-option .email-sending-topic {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.email-option .email-display {
  color: #9d9d9d;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.alert-box .alert-box-main-topic {
  text-align: center;
  font-family: "Orelega One";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.plan-details h4 {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.switch-plan label {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.card-details .card-id {
  margin: 0px;
}

.row-main-container {
  display: flex;
  gap: 55px;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.check-box-pricing-main {
  display: flex;
}

.check-box-pricing {
  margin: 25px 5px 25px 5px;
}

.price-dropdown-section-ul {
  display: flex;
  gap: 15px;
}

.price-dropdown-section-li {
  background-color: #25242f;
  color: #ffffff;
  font-family: "Public Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 9px 10px 9px 10px;
  border-radius: 100px;
}

.price-dropdown-section-li .remove-button {
  background-color: #ffffff;
  border-radius: 100px;
  margin-left: 5px;
}

.add-del-03 {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.spc-price-tbl {
  margin-top: 32px;
  margin-bottom: 80px;
}

.add-del-03 .add-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spc-price-tbl tbody,
.spc-price-tbl thead {
  position: relative;
}

.spc-price-tbl tbody:after,
.spc-price-tbl thead:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 1px;
  background: #d6d6d6;
}

.paid-status {
  display: flex;
  padding: 11px 27px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: #ecfdf3;
  color: #397f60;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  text-transform: capitalize;
}

.overflow-controller span {
  padding: 11px 27px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  font-family: "Public Sans";
  font-size: 12px;
}

.spc-price-tbl .profile-card-details h5 {
  margin: 0;
}

.dpick02 .react-datepicker {
  position: absolute;
  right: 0px;
  bottom: -235px;
}

.dpick02 {
  display: inline-flex;
  padding: 10px 10px;
}

.form-group {
  font-family: "Orelega One", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}

.course-input {
  border-radius: 120px;
  background: #fff;
  align-items: center;
  gap: 321px;
  align-self: stretch;
  border: 1px solid #d6d6d6; /* Define all border properties */
  padding-left: 25px;
  font-family: "Moranga";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  width: 490px;
  height: 40px;
}

.course-input::placeholder {
  color: #cdcdcd;
  margin-left: 5px;
}

.table-head {
  background-color: #fbf8f3;
  color: #7d7d7d;
  border-radius: 8px;
}

th,
td {
  padding: 20px;
}

th {
  text-align: left;
}

.table-head .topic {
  padding-right: 80px;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 1305px;
  margin: 0 auto;
}

.overflow-controller {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-details {
  display: flex;
}

.form-group {
  font-family: "Orelega One", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}

.search-field:focus {
  outline: none;
  border: none;
}

.date-picker {
  /* display: flex;
    background-color: #050505;
    padding: 10px;
    border-radius: 6px;
    gap: 6px;
    width: 146px; */

  display: inline-flex;
  padding: 8px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 6.721px;
  background: #fff;
  color: #cccccc;
  border: 1px solid #d6d6d6;
  width: 130%;
}

.date-picker__input {
  background: transparent;
  border: none;
  width: 80px;
}

.date-picker__input::placeholder {
  color: #fff;
  font-family: "Orelega One";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  text-transform: capitalize;
}

.card {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
  border-radius: 12px;
  transition: all 0.3s;
}

.chart-card-container {
  display: flex !important;
}

.chart-date {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.user-profile-card {
  display: flex;
  flex: 1 1 30%;
  box-sizing: border-box;
  align-items: center;
}

.user-profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user-card-title {
  color: #25242f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Orelega One";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 222.222% */
  text-transform: capitalize;
  margin: 0;
}

.user-date {
  color: #7d7d7d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
  margin: 0;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 25px;
}

.filter-option {
  display: flex;
  width: 189px;
  padding: 13px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  color: #cccccc;
}

.paid-status {
  display: flex;
  padding: 5px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: #ecfdf3;

  color: #397f60;
  font-family: "Public Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
  text-transform: capitalize;
}

.view-invoice {
  color: #25242f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 133.333% */
  text-decoration-line: underline;
  text-transform: capitalize;
}

.cancel-status {
  display: flex;
  padding: 5px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: #fcefef;

  color: #e82424;
  font-family: "Public Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
  text-transform: capitalize;
}

.spc-banner-top {
  position: relative;
}

.spc-banner-top .react-datepicker {
  position: absolute;
  right: 0px;
  bottom: -200px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 7px 28px 0px rgba(0, 0, 0, 0.05);
  z-index: 9;
}

.date-picker__input {
  background: transparent;
  border: none;
  width: 90px;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.card:hover .rec {
  background: #fff;
  transition: all 0.3s;
}

.card:hover .rec svg {
  fill: #000000;
  transition: all 0.3s;
}

.card .card-title {
  margin: 0;
}

.card .card-para {
  margin: 0;
}

.chart-card {
  border-radius: 12px;
  border: 1.12px solid #f0f0f0;
  background: #fff;
  padding: 40px 35px;
}

.canvasjs-chart-canvas {
  width: 500px !important;
}

.chart-maps {
  display: flex;
  margin-top: 60px;
  gap: 12px;
}

.map-title {
  color: #7d7d7d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Orelega One";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}

.earnings {
  margin: 0;
}

.calc .percentage p {
  margin: 0;
  padding-left: 20px;
  color: #397f60;
  leading-trim: both;
  text-edge: cap;
  font-family: "Orelega One";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}

.chart-card-container {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
}

.chart-card-container .react-datepicker {
  position: absolute;
  right: 0px;
  bottom: -228px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 7px 28px 0px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.user-profile-container {
  padding-bottom: 100px;
  padding-top: 30px;
  gap: 24px;
}

.user-card-title {
  line-height: normal;
}

.user-date {
  color: #7d7d7d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: unset;
  margin: 0;
  margin-top: 3px;
}

.user-profile-card {
  display: flex;
  flex: 1 1 30%;
  box-sizing: border-box;
  align-items: center;
  gap: 12px;
}

.dropdown-menu {
  position: absolute;
  top: 45px;
  left: -195px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 4px;
  width: max-content;
}

.set-prof-hdr {
  margin: 30px 40px 50px 0;
}

.set-prof-hdr2 {
  margin: 60px 40px 50px 0;
}

.row-main-container {
  display: flex;
  gap: 020px;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1305px;
  margin: 0 auto;
}

.log-input {
  border-radius: 120px;
  background: #f8f8f8;
  align-items: center;
  gap: 321px;
  align-self: stretch;
  border: 1px solid #d6d6d6;
  padding-left: 25px;
  font-family: "Moranga";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  width: 260px;
  height: 40px;
}

.row-main-container {
  gap: 24px;
}

.set-prof-hdr .form-group {
  gap: 3px;
}

.spc-sub-input {
  margin-bottom: 24px;
}

.test-image-logo {
  border-radius: 30px;
  width: 50px;
  border: 2px solid #687362;
  padding: 3px;
  height: 50px;
  object-fit: cover;
}

.set-prof-hdr2 .form-group {
  gap: 3px;
}

.form-group input {
  color: #9d9d9d;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding-left: 25px;
}

.width-reduce-span {
  width: 70%;
  display: block;
}

.dashboardText > h1 {
  color: #25242f;
  font-family: "Orelega One", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 40px;
  text-transform: capitalize;
  width: 315px;
  height: 14px;
  height: unset;
  margin: 0px;
  margin-bottom: -9px;
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 30px;
}

.f-g2 input[type="email"] {
  width: 400px;
  outline: none;
}

.drp-fil0 {
  margin-left: 32px;
}

.rw-spc {
  margin-top: 40px !important;
}

.filter-option {
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  text-transform: capitalize;
  outline: none;
}

.spc-sec-btn-date input {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  border-radius: 50px;
}

.spc-d-pic {
  border-radius: 50px;
  width: 115%;
}

.user-table tbody {
  position: relative;
}

.user-table tbody:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 1px;
  background: #d6d6d6;
}

.user-table tbody:last-child:after {
  content: none;
}

.user-table thead {
  position: relative;
}

.user-table thead:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 1px;
  background: #d6d6d6;
}

.overflow-controller span {
  width: fit-content;
}

.spc-add-del {
  margin-bottom: 5px;
}

.data-p-2-spc {
  position: relative;
}

.data-p-2-spc .react-datepicker {
  position: absolute;
  right: -75px;
  bottom: -244px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 7px 28px 0px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.form-group .table-main-topic {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.react-datepicker {
  position: absolute;
  right: 0px;
  bottom: -244px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 7px 28px 0px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

.react-datepicker {
  position: absolute;
  right: 0px;
  bottom: -244px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 7px 28px 0px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.card-details-main .default-badge {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #25242f;
  color: #25242f;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 15px;
  width: 55px;
}

.card-details-main .card-id {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.card-details-main .expiry {
  color: #9d9d9d;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.card-details-main .card-actions {
  display: flex;
  gap: 35px;
}

.err-svg-profile {
  margin-bottom: -4px;
}
.new-wrapper-image {
  width: auto;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 40px;
}
.new-wrapper-image .profile-img {
  margin: 0;
  margin-top: -64px;
}
@media (max-width: 678px) {
  .subscription-container-sub {
    display: flex;
    gap: 20px;
    margin: 20px 0px;
    flex-direction: column;
  }

  .subscription-container-sub .section-1-subscription {
    width: 100%;
  }

  .alert-box {
    background-color: #fff5f5;
    color: #f65c5c;
    border: 1px solid #f65c5c;
    padding: 5px 17px;
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-family: "Orelega One";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 90%;
  }

  .current-plan-box {
    background-color: #f8f7f2;
    border: 1px solid #cfc8a8;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 88%;
    gap: 4px;
  }

  .billing-common-right .card-details-main {
    display: flex;
    gap: 18px;
    flex-direction: column;
  }

  .billing-common-right .card-info-sub {
    display: flex;
    width: 290px;
    justify-content: space-between;
  }

  .billing-common-right .card-info {
    border-radius: 8px;
    border: 1px solid #cfc8a8;
    background: none;
    display: flex;
    width: 278px;
    padding: 24px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 15px 0px;
  }

  .card-info.selected {
    background-color: #f8f7f2;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
  }

  .course-input {
    border-radius: 120px;
    background: #fff;
    align-items: center;
    gap: 321px;
    align-self: stretch;
    border: 1px solid #d6d6d6;
    padding-left: 25px;
    font-family: "Moranga";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    width: 300px;
    height: 40px;
  }

  .main-container-table {
    overflow: hidden;
    overflow-x: scroll;
  }

  .slider-set-default {
    width: 85%;
    margin-top: 60px;
    margin-bottom: 0px;
  }

  .card {
    display: flex;
    padding: 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    flex: 1 0;
    border-radius: 12px;
    transition: all 0.3s;
  }

  .subscription-container {
    font-family: Arial, sans-serif;
    margin: 0px;
    border-bottom: 1px solid #d6d6d6;
  }

  .drp-fil0 {
    margin-left: 0px;
  }

  .filter-option {
    display: flex;
    width: 325px;
    padding: 13px 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    color: #cccccc;
  }

  .spc-d-pic {
    border-radius: 50px;
    width: 200%;
  }

  .data-p-2-spc .react-datepicker {
    position: absolute;
    right: 45px;
    bottom: -244px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 7px 28px 0px rgba(0, 0, 0, 0.05);
    z-index: 99;
  }

  .billing-common-right .input-mail-logo {
    display: flex;
    align-items: center;
    padding: 5px 24px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 85%;
  }

  .current-plan-box .plan-details-price {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 22px;
  }
  .new-wrapper-image {
    width: auto;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    gap: 0;
    flex-direction: column;
    padding: 25px;
  }
  .section-title {
    margin-top: 0;
    padding-top: 33px;
    margin-bottom: 10px;
  }
  .profile-cover {
    height: 132px;
    background: linear-gradient(135deg, #9bb7c5 0%, #527a6a 100%);
  }
}

.add-delete-btn .date-picker input::placeholder {
  color: #ccc;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: capitalize;
}

.relative-date-picker {
  position: relative;
  height: fit-content;
}

.relative-date-picker .left-svg {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.relative-date-picker .rgt-svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.spc-seris-01 {
  padding-bottom: 5vh;
}

.spc-price-crd .section-2-price {
  background: #fff;
  margin-top: -5vh;
}
@media (min-width: 768px) and (max-width: 1000px) {
  .section-two {
    margin: -4% 24px 24px;
  }
  .tab-selections {
    align-items: center;
    align-self: stretch;
    background: #fff;
    border: 1px solid #d6d6d6;
    border-radius: 120px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    margin: 0 auto;
    padding: 12px 24px;
    width: 100%;
    width: 49%;
  }
  .tab-container {
    padding: 57px 0;
  }
  .profile-img {
    border-radius: 100%;
    height: 100px;
    margin-top: 22%;
    object-fit: cover;
    width: 100px;
  }
  .section-sub-title {
    margin: 0;
  }
  .section-title {
    margin: 0;
  }
  .series-slider-wrapper .slick-prev {
    left: 86% !important;
  }
  .profile-section-wrap {
    background: #f8f7f2;
    margin-top: -127px;
  }
}
@media (min-width: 1023px) and (max-width: 1199px) {
  .profile-img {
    margin-top: 15%;
  }
  .user-profilesliders .series-slider-wrapper .slick-prev {
    left: 818px !important;
  }
  .user-profilesliders .slider-set-category {
    padding: 0px 44px;
  }
  .user-profilesliders .series-slider-wrapper .slick-next {
    right: 44px;
  }
  .new-wrapper-image {
    width: auto;
    max-width: 980px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 40px;
  }
}
@media (min-width: 1024px) {
  .left-pay-control {
    width: 30%;
  }
  .rgt-pay-control {
    width: 58%;
  }
  .rgt-pay-control .card-info {
    width: 600px;
  }
  .rgt-pay-control .card-info-sub {
    width: 100%;
  }
}
