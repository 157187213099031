/* Container and Layout */
.profile-checkout-container {
    padding-top: 80px ;
}
.flex-checkout{
    display: flex;
    align-items: flex-start;
    padding-top: 40px;
    border-top: 1px solid #D6D6D6;
    gap: 100px;
    padding-bottom: 40px;
}
.profile-checkout-heading {
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    text-transform: capitalize;
    margin: 0;
}

.profile-checkout-subheading {
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
    margin-bottom: 40px;
}

/* Form Styles */
.profile-checkout-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
}

.profile-checkout-billing-info,
.profile-checkout-card-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-checkout-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #fff;
    box-sizing: border-box;
}

.profile-checkout-input:focus {
    outline: none;
    border-color: #7a9c87;
}

.profile-checkout-input[type="email"] {
    font-family: "Arial", sans-serif;
}

.profile-checkout-submit-btn {
    border-radius: 50px;
    background: #687362;
    display: flex;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    border: none;
    transition: background-color 0.3s ease;
}

.profile-checkout-submit-btn:hover {
    background-color: #6a8b76;
}
.profile-checkout-form label{
    color: #25242F;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.profile-checkout-input:focus {
    outline: none;
    border-color: #D6D6D6;
    border: 1px solid #D6D6D6;
}
.profile-checkout-input{
    border-radius: 120px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    display: flex;
    height: 40px;
    padding: 14px 24px;
    align-items: center;
    gap: 321px;
    align-self: stretch;
}
.profile-checkout-billing-info{
    margin-bottom: 20px ;
    padding-bottom: 40px;
    border-bottom: 1px solid #D6D6D6 ;
}
.sub-lbl-chechout{
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.main-box-checkout{
    color: #25242F;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    text-transform: capitalize;
    display: block;
}
.sub-box-checkout{
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    display: block;
    margin-bottom: 40px;
}
.profile-checkout-card-details input{
    margin-bottom: 24px;
}
.wrapper-secure-info{
    display: flex;
    align-items: flex-start;
   gap: 24px;
}
.wrapper-secure-info > div{
    width: 50%;
}
/* Message Styles */
.profile-checkout-message {
    border-radius: 12px;
    background: #687362;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.profile-checkout-message.error {
    background-color: #f8d7da;
    color: #721c24;
}

.profile-checkout-message.success {
    background-color: #d4edda;
    color: #155724;
}


.profile-checkout-pricing {
    background-color: #69795e;
    color: #fff;
    padding: 40px;
    border-radius: 10px;
    border-radius: 12px;
    background: #687362;
    width: 45%;
}

.profile-checkout-plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid;
}

.profile-checkout-plan-title {
    color: #FFF;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
}

.profile-checkout-plan-change {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    width: 120px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #FFF;
    color: #25242F;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.profile-checkout-plan-options {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid;
}

.profile-checkout-plan-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.profile-checkout-plan-option input[type="radio"] {
    margin-right: 10px;
}

.profile-checkout-save-badge {
    background-color: #fff;
    color: #69795e;
    padding: 2px 5px;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: 3px;
    margin-left: 10px;
}

.profile-checkout-plan-description {
    color: #FFF;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding-bottom: 110px;
}

.profile-checkout-terms-link {
    color: #fff;
    text-decoration: underline;
}

.profile-checkout-plan-summary {
    font-size: 1rem;
    padding-top: 15px;
}

.profile-checkout-summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px dashed #D6D6D6;
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
}

.profile-checkout-summary-total {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    padding-top: 24px;
    border-top: 1px solid #D6D6D6;
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}
.profile-checkout-plan-price span{
    color: #FFF;
    text-align: right;
    font-family: "Orelega One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
}
.profile-checkout-plan-option {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}

.profile-checkout-plan-option input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.profile-checkout-plan-option .profile-checkout-plan-label {
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.profile-checkout-plan-option .profile-checkout-plan-badge {
    background-color: #f5f5f5;
    color: #333;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 12px;
    margin-left: 8px;
    font-weight: normal;
}

.profile-checkout-plan-option::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.profile-checkout-plan-option.active::before {
    background-color: #fff;
}

.profile-checkout-plan-option input[type="radio"]:checked + .profile-checkout-plan-label + .profile-checkout-plan-badge {
    display: inline-block;
}
.specil-checkout-note, .specil-checkout-note a{
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 80px;
}
.close-btn-com{
    position: absolute;
    top: 32%;
    left: 59%;
    border: none;
    font-size: 15px;
    font-weight: 700;
    height: 28px;
    width: 29px;
    border-radius: 50%;
}
@media (max-width:768px){
    /* Container and Layout */
    .profile-checkout-container {
        padding-top:40px ;
        width: 85%;
        margin: 0 auto;
    }
    .flex-checkout{
        display: flex;
        align-items: flex-start;
        padding-top: 40px;
        border-top: 1px solid #D6D6D6;
        gap: 40px;
        flex-direction: column-reverse;
    }
    .profile-checkout-heading {
        color: #25242F;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        text-transform: capitalize;
    }

    .profile-checkout-subheading {
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }

    /* Form Styles */
    .profile-checkout-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .profile-checkout-billing-info,
    .profile-checkout-card-details {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .profile-checkout-input {
        width: 100%;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        font-size: 1rem;
        background-color: #fff;
        box-sizing: border-box;
    }

    .profile-checkout-input:focus {
        outline: none;
        border-color: #7a9c87;
    }

    .profile-checkout-input[type="email"] {
        font-family: "Arial", sans-serif;
    }

    .profile-checkout-submit-btn {
        border-radius: 50px;
        background: #687362;
        display: flex;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        cursor: pointer;
        text-align: center;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        border: none;
        transition: background-color 0.3s ease;
    }

    .profile-checkout-submit-btn:hover {
        background-color: #6a8b76;
    }
    .profile-checkout-form label{
        color: #25242F;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .profile-checkout-input:focus {
        outline: none;
        border-color: #D6D6D6;
        border: 1px solid #D6D6D6;
    }
    .profile-checkout-input{
        border-radius: 120px;
        border: 1px solid #D6D6D6;
        background: #FFF;
        display: flex;
        height: 40px;
        padding: 14px 24px;
        align-items: center;
        gap: 321px;
        align-self: stretch;
    }
    .profile-checkout-billing-info{
        margin-bottom: 20px ;
        padding-bottom: 40px;
        border-bottom: 1px solid #D6D6D6 ;
    }
    .sub-lbl-chechout{
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .main-box-checkout{
        color: #25242F;
        font-family: "Orelega One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 120% */
        text-transform: capitalize;
    }
    .sub-box-checkout{
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 20px;
    }
    .profile-checkout-card-details input{
        margin-bottom: 24px;
    }
    .wrapper-secure-info{
        display: flex;
        align-items: flex-start;
    gap: 24px;
    }
    .wrapper-secure-info > div{
        width: 50%;
    }
    /* Message Styles */
    .profile-checkout-message {
        border-radius: 12px;
        background: #687362;
        display: flex;
        padding: 40px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .profile-checkout-message.error {
        background-color: #f8d7da;
        color: #721c24;
    }

    .profile-checkout-message.success {
        background-color: #d4edda;
        color: #155724;
    }


    .profile-checkout-pricing {
        width: 85%;
        display: flex;
        padding: 40px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        margin: 0 auto;
    }

    .profile-checkout-plan-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid;
        width: 100%;
    }

    .profile-checkout-plan-title {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 120% */
    }

    .profile-checkout-plan-change {
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 90px;
    }

    .profile-checkout-plan-options {
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid;
    }

    .profile-checkout-plan-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .profile-checkout-plan-option input[type="radio"] {
        margin-right: 10px;
    }

    .profile-checkout-save-badge {
        background-color: #fff;
        color: #69795e;
        padding: 2px 5px;
        font-size: 0.75rem;
        font-weight: bold;
        border-radius: 3px;
        margin-left: 10px;
    }

    .profile-checkout-plan-description {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        padding-bottom: 60px;
    }

    .profile-checkout-terms-link {
        color: #fff;
        text-decoration: underline;
    }

    .profile-checkout-plan-summary {
        font-size: 1rem;
        padding-top: 15px;
        width: 100%;
    }

    .profile-checkout-summary-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        border-top: 1px dashed #D6D6D6;
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 150% */
        width: 100%;
    }

    .profile-checkout-summary-total {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 120% */
    }
    .profile-checkout-plan-price span{
        color: #FFF;
        text-align: right;
        font-family: "Orelega One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 160% */
    }
    .profile-checkout-plan-option {
        position: relative;
         padding-left: 10px; 
        margin-bottom: 10px;
        font-family: Arial, sans-serif;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        gap:auto;
        justify-content: space-between;
        width: 100%;
    }

    .profile-checkout-plan-option input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .profile-checkout-plan-option .profile-checkout-plan-label {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }

    .profile-checkout-plan-option .profile-checkout-plan-badge {
        background-color: #f5f5f5;
        color: #333;
        font-size: 12px;
        padding: 2px 8px;
        border-radius: 12px;
        margin-left: 8px;
        font-weight: normal;
    }

    .profile-checkout-plan-option::before {
        content: '';
        position: absolute;
        top: 17px;
        left: -14px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 2px solid #fff;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .profile-checkout-plan-option.active::before {
        background-color: #fff;
    }

    .profile-checkout-plan-option input[type="radio"]:checked + .profile-checkout-plan-label + .profile-checkout-plan-badge {
        display: inline-block;
    }
    .specil-checkout-note, .specil-checkout-note a{
        color: #7D7D7D;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 40px;
    }

}