.add-payment-method{
    position: relative;
}
.AddNewPaymentMethod-wrapper {
    align-items: center;
    background: #000000b5;
    border-radius: 0;
    box-shadow: 0 50px 100px -20px #32325d40, 0 30px 60px -30px #0000004d, inset 0 -2px 6px 0 #0a254059;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    padding: 25px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999999;
    align-content: center;
}
.AddNewPaymentMethod-wrapper .profile-checkout-form{
    width: 100%;
    padding: 40px;
    border: 1px solid;
    border-radius: 12px;
    max-width: 440px;
    height: fit-content;
    background-color: white;
}
.addcard-flx{
    display: flex;
    align-items: center;
    gap: 24px;
}

.profile-not-checkout-submit-btn{
    display: flex;
    padding: 9px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
border: 1.5px solid #687362;
}
.EditNewPaymentMethod-wrapper{
    position: absolute;
    z-index: 99;
    padding: 25px;
    border-radius: 12px;
    left: 32%;
}
.payment-wrapper-container {
    background: #0000004e;
    position: absolute;
    z-index: 99;
    height: 100vh;
}
.EditNewPaymentMethod-wrapper .profile-checkout-form{
    width: 100%;
}

@media (max-width: 768px) {
    .AddNewPaymentMethod-wrapper, .EditNewPaymentMethod-wrapper {
        background: #00000063;
        box-shadow: 0 50px 100px -20px #32325d40, 0 30px 60px -30px #0000004d, inset 0 -2px 6px 0 #0a254059;
        padding: 0;
        position: fixed;
        height: 100vh;
        width: 100%;
        z-index: 99;
        left: 0;
        border-radius: 0;
        top: 0;
    }
    .AddNewPaymentMethod-wrapper .profile-checkout-form {
        width: 75%;
    }
    .input-mail-logo-wrapper {
       display: flex;
       width: 100%;
       flex-direction: column;
        gap: 12px;
        margin-top: 20px;
     }
}

@media(max-width:768px){
    .AddNewPaymentMethod-wrapper {
        position: absolute;
        background: none;
        width: -moz-fit-content;
        width: fit-content;
        z-index: 99;
        left: 2%;
        top: -224px;
        border-radius: 18px;
        margin: 0 auto;
        padding: 25px;
        box-shadow: none;
        width: 82%;
    }
    .EditNewPaymentMethod-wrapper {
        position: absolute;
        z-index: 99;
        background: #fff;
        padding: 0px;
        border-radius: 12px;
        left: 6%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        width: 77%;
        height: 0;
    }
}
@media (min-width:1200px){
    .AddNewPaymentMethod-wrapper .profile-checkout-form {
        width: 100%;
        padding: 40px;
        border: 1px solid;
        border-radius: 12px;
        max-width: 440px;
        height: -moz-fit-content;
        height: fit-content;
        background-color: white;
        max-height: 84vh;
        overflow-y: auto;
        margin-bottom: 6vh;
        scrollbar-color: #687362 #e0e0e0;
        scrollbar-width: thin;
        scrollbar-gutter: stable;
    }
}