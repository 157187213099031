.otp-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.otp-popup-content {
  background-color: white;
  padding: 20px;
  width: 350px;
  border-radius: 10px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.otp-input {
  width: 40px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.otp-input:focus {
  border-color: #000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.timer {
  margin: 10px 0;
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.timer span {
  color: #25242f;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.confirm-btn {
  background-color: black;
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.confirm-btn:hover {
  background-color: #444;
}

.resend-otp {
  margin-top: 10px;
  font-size: 12px;
  color: gray;
}

.resend-otp a {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.otp-popup-content h2 {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
}

.otp-popup-content .popup-details {
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.otp-popup-content .popup-details span {
  color: #25242f;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

.otp-popup-content .enter-otp {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.otp-popup-content .resend-otp {
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.otp-popup-content .resend-otp a {
  color: #25242f;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

.otp-input-container .otp-input {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.otp-input-container .otp-input::placeholder {
  color: #d6d6d6;
  font-family: "Orelega One";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 678px) {
  .otp-popup-content {
    background-color: white;
    padding: 8px;
    width: 350px;
    border-radius: 10px;
    position: relative;
  }
  .otp-popup-content h2 {
    color: #25242f;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    margin: 0px;
  }
  .otp-popup-content .popup-details {
    color: #7d7d7d;
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
  .otp-popup-content .popup-details span {
    color: #25242f;
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
  }
  .otp-popup-content .enter-otp {
    color: #25242f;
    font-family: "Orelega One";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    margin: 0px;
  }
  .otp-input-container .otp-input {
    color: #25242f;
    font-family: "Orelega One";
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    border-radius: 4px;
    width: 14px;
    height: 20px;
    padding: 16px;
  }
  .otp-input-container .otp-input::placeholder {
    color: #d6d6d6;
    font-family: "Orelega One";
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .otp-popup-content .resend-otp {
    color: #7d7d7d;
    font-family: "Public Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .otp-popup-content .resend-otp a {
    color: #25242f;
    font-family: "Public Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
  }
  .confirm-btn {
    background-color: black;
    color: white;
    padding: 9px 12px;
    width: 100%;
    border: none;
    border-radius: 100px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 20px;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
