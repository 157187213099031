.email-vari-flex {
  display: flex;
  align-items: flex-start;
}

.email-vari-container {
  width: 100%;
  margin: 0 auto;
  background: #f8f7f2;
  height: 100vh;
  overflow: hidden;
}
.left-email-vari-div,
.right-email-vari-div {
  width: 50%;
}
.right-email-vari-div {
  position: relative;
  background-color: #25242f;
}
.right-email-vari-div img {
  width: 100%;
  height: 99vh;
  object-fit: cover;
  opacity: 0.7;
}
.left-email-vari-div {
  display: flex;
  padding: 0 60px 0;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  height: 100vh;
  align-content: flex-end;
  justify-content: center;
  overflow-y: auto;
}
.right-email-vari-div .email-vari-banner-abs-text {
  position: absolute;
  z-index: 2;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  width: 500px;
}
.left-email-vari-div .welcome-back-tag {
  width: 100%;
  color: #25242f;
  text-align: center;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 133.333% */
  text-transform: capitalize;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 16px;
}
.left-email-vari-div .welcome-sub-tag {
  color: #7d7d7d;
  width: 100%;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 142.857% */
  margin: 0;
}
.left-email-vari-div form {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #ccc;
  width: 75%;
}
.left-email-vari-div form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.left-email-vari-div form .form-group .input-mail-logo {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  width: 90.5%;
}

.left-email-vari-div form .form-group-one {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.left-email-vari-div form label {
  color: #7d7d7d;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 12px;
}

.left-email-vari-div form .form-group-one label {
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;

}

.left-email-vari-div form .form-group input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 120px;
  border: none;
  background: #fff;
  width: 100%;
  height: 20px;
}

input:focus {
  outline: none;
}

input::placeholder {
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #ccc;
}

.wrp-email-vari {
  position: relative;
  width: 100%;
}
.wrp-email-vari svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.left-email-vari-div .form-group-flx {
  display: flex;
  gap: 8px;
  justify-content: baseline;
  margin-bottom: 40px;
}
.left-email-vari-div .form-group-flx label,
.left-email-vari-div .form-group-flx label a {
  color: #7d7d7d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.left-email-vari-div .email-vari-button {
  display: flex;
  width: 100%;

  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 50px;
  background: #687362;
  margin-top: 40px;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.email-vari-footer p {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.email-vari-footer a {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.form-group-one a {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black; 
  transition: background-color 5000s ease-in-out 0s;
}
.email-vari-container a img{
  width: 220px;
}
@media (max-width: 678px) {
  .right-email-vari-div {
    display: none;
  }
  .left-email-vari-div form .form-group .input-mail-logo {
    display: flex;
    align-items: center;
    padding: 5px 24px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 85%;
  }
  .left-email-vari-div {
    display: flex;
    padding: 0 24px 0;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    align-self: stretch;
    height: 100vh;
    align-content: flex-end;
    justify-content: center;
    overflow-y: auto;
    width: 100%;
  }
  .left-email-vari-div .welcome-back-tag {
    width: 100%;
    color: #25242f;
    text-align: center;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: capitalize;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .left-email-vari-div .welcome-sub-tag {
    color: #7d7d7d;
    width: 100%;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0;
  }
  .left-email-vari-div form {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #ccc;
    width: 85%;
    margin: 40px auto;
  }
  .left-email-vari-div form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .left-email-vari-div form label {
    color: #7d7d7d;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
  }
  .left-email-vari-div form input {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 85%;
  }
  .left-email-vari-div .form-group-one input {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 5%;
    margin: 5px 10px 25px 5px;
  }
  .wrp-email-vari {
    position: relative;
    width: 100%;
  }
  .wrp-email-vari svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  .left-email-vari-div .form-group-flx {
    display: flex;
    gap: 8px;
    justify-content: baseline;
    margin-bottom: 40px;
  }
  .left-email-vari-div .form-group-flx label,
  .left-email-vari-div .form-group-flx label a {
    color: #7d7d7d;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .left-email-vari-div .email-vari-button {
    display: flex;
    width: 100%;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 50px;
    background: #687362;
    margin-top: 40px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
  .left-email-vari-div form .form-group-one {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }
  .left-email-vari-div form {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #ccc;
    width: 100%;
    margin: 40px auto;
}
}
