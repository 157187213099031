.login-flex {
    display: flex;
    align-items: flex-start;

}

.login-container {
    width: 100%;
    margin: 0 auto;
    margin-top: 0vw;
    background: #F8F7F2;
    height: 100vh;
    overflow: hidden;
}

.left-login-div, .right-login-div {
    width: 50%;
}

.right-login-div {
    position: relative;
    background: #000;
    height: 100vh;
}

.right-login-div img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 0.7;
}

.left-login-div {
    display: flex;
    padding: 0 60px 0;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    align-self: stretch;
    height: 100vh;
    align-content: flex-end;
    justify-content: center;
    overflow-y: auto;
}


.left-login-div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.left-login-div::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.left-login-div::-webkit-scrollbar-thumb {
    background-color: #AAA;
    border-radius: 10px;
}

.right-login-div .login-banner-abs-text {
    position: absolute;
    z-index: 2;
    bottom: 100px;
    left: 51%;
    transform: translateX(-50%);
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    width: 500px;
}

.left-login-div .welcome-back-tag {
    width: 100%;
    color: #25242F;
    text-align: center;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 112.5% */
    text-transform: capitalize;
    margin: 0;
    margin-top: 29px;
    margin-bottom: 16px;
}

.left-login-div .welcome-sub-tag {
    color: #7D7D7D;
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
}

.left-login-div form {
    margin: 0 auto;
    margin-top: 29px;
    width: 90%;
    padding-top: 29px;
    border-top: 1px solid #CCC;
}

.left-login-div form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.left-login-div form label {
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
}

.left-login-div form input {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    width: 92%;
    outline: none;
}

.left-login-div form input::placeholder {
    color: #CCC;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 171.429% */
}

.wrp-login {
    position: relative;
    width: 100%;
}

.wrp-login svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.left-login-div .form-group-flx {
    display: flex;
    gap: 8px;
    justify-content: baseline;
    margin-bottom: 40px;
}

.left-login-div .form-group-flx label, .left-login-div .form-group-flx label a {
    color: #7D7D7D;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.left-login-div .login-button {
    display: flex;
    width: 100%;

    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 50px;
    background: #687362;
    margin-top: 40px;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
}

.left-login-div .login-footer, .left-login-div .login-footer a {
    margin-top: 24px;
    color: #7D7D7D;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.forgot-password-login a {
    text-decoration: none;
}

.left-login-div .error-text, .right-side-us .error-text {
    color: #F65C5C;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
}

.width-login {
    width: 60%;
}
.login-container a img{
    width: 220px;
}
@media (max-width: 678px) {
    .right-login-div {
        display: none;
    }

    .left-login-div {
        display: flex;
        padding: 0 24px 0;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        align-self: stretch;
        height: 100vh;
        align-content: flex-end;
        justify-content: center;
        overflow-y: auto;
        width: 100%;
    }

    .left-login-div .welcome-back-tag {
        width: 100%;
        color: #25242F;
        text-align: center;
        font-family: "Orelega One";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
        text-transform: capitalize;
        margin: 0;
        margin-top: 40px;
        margin-bottom: 16px;
    }

    .left-login-div .welcome-sub-tag {
        color: #7D7D7D;
        width: 100%;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 0;
    }

    .left-login-div form {
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid #CCC;
    }

    .left-login-div form .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    }

    .left-login-div form label {
        color: #7D7D7D;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 12px;
    }

    .left-login-div form input {
        display: flex;
        padding: 0px 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 120px;
        border: 1px solid #D6D6D6;
        background: #FFF;
        width: 85%;
        height: 40px;
    } 

    .wrp-login {
        position: relative;
        width: 100%;
    }

    .wrp-login svg {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    .left-login-div .form-group-flx {
        display: flex;
        gap: 8px;
        justify-content: baseline;
        margin-bottom: 40px;
    }

    .left-login-div .form-group-flx label, .left-login-div .form-group-flx label a {
        color: #7D7D7D;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .left-login-div .login-button {
        display: flex;
        width: 100%;

        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 50px;
        background: #687362;
        margin-top: 40px;
        border: none;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
    }

    .left-login-div .login-footer, .left-login-div .login-footer a {
        margin-top: 24px;
        color: #7D7D7D;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .forgot-password-login a {
        text-decoration: none;
    }
    .left-login-div form {
        margin: 0 auto;
        margin-top: 29px;
        width: 100%;
        padding-top: 29px;
        border-top: 1px solid #CCC;
    }
}

.login-signup-photo-name svg{
    position: absolute;
    right: 0;
}
@media (min-width:768px) and (max-width:1000px){

    .right-login-div {
        display: none;
    }
    .left-email-vari-div, .right-email-vari-div {
        width: 88%;
    }
    .left-email-vari-div {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        padding: 85px 1px;
    }
    .right-login-div .login-banner-abs-text {
        font-family: Orelega One;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        width: 94%;
    }
    .login-signup-photo-name svg{
        position: absolute;
        left: 50%;
    }
}
@media (min-width:1023px) and (max-width:1199px){
    .right-login-div .login-banner-abs-text {
        width: 94%;
    }
}
@media (max-width:600px){
    .login-signup-photo-name svg{
        left: 75%;
    }
}
