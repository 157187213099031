.not-f-main{
    height: 100vh;
}
.notfound-wrapper-abs{
    display: flex;
    width: 31%;
    text-align: center;
    justify-content: center;
    align-items: left;
    position: relative;
    z-index: 4;
    height: 100%;
    flex-direction: column;
    margin-top: -50px;
    margin-left: 100px;
}
.abs-nf-images{
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    background: #000;
    height: 110vh;

}
.abs-nf-images img{
    height: 110vh;
    width: 100%;
    object-fit: cover;
    opacity: 0.7;
    background: #000;
}
.nf-abs-txt{
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 50% */
    text-transform: capitalize;
    margin-bottom: 18px;
}
.nf-abs-txt2{
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 112.5% */
    text-transform: capitalize;
    margin-bottom: 39px;
}
.nf-abs-para{
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    margin-bottom: 39px;
}
.nf-pg-name{
    display: flex;
    width: 255px;
    padding: 11px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #FFF;
    color: #25242F;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-transform: capitalize;
    text-decoration: none;
    margin: 0 auto;
}


@media (max-width:768px){
    .not-f-main{
        height: 100vh;
    }
    .notfound-wrapper-abs{
        display: flex;
        width: 85%;
        text-align: center;
        justify-content: center;
        align-items: left;
        position: relative;
        z-index: 4;
        height: 100%;
        margin-left: unset;
        flex-direction: column;
        margin: 0 auto;
    }
    .abs-nf-images{
        position: absolute;
        z-index: 0;
        top: 0;
        width: 100%;
        background: #000;
        height: 115vh;
    
    }
    .abs-nf-images img{
        height: 115vh;
        width: 100%;
        object-fit: cover;
        opacity: 0.7;
        background: #000;
    }
    .nf-abs-txt{
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 75% */
        text-transform: capitalize;
        margin-bottom: 14px;
    }
    .nf-abs-txt2{
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        text-transform: capitalize;
        margin-bottom: 24px;
    }
    .nf-abs-para{
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 39px;
    }
    .nf-pg-name{
        display: flex;
        width: fit-content;
        padding: 11px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: #FFF;
        color: #25242F;
        text-align: center;
        font-family: "Orelega One";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        text-decoration: none;
        margin: 0 auto;
    }
}