/* Base styles - Mobile first */

/* Small devices */
@media (max-width: 768px) {
  .section-1-contact {
    background-image: url("../images/image21.png");
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.076) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-1-contact h1 {
    font-size: 48px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-contact {
    background-color: #f8f7f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px;
    width: 85%;
    margin: 60px auto;

  }
  .section-2-contact-details {
    padding: 40px 20px 40px 20px;
  }

  .section-2-contact-details .details-main {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0px;
  }

  .section-2-contact-details .details-sub {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .section-2-contact .topic {
    color: #25242f;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }

  .section-2-contact-button {
    margin-top: 20px;
    padding: 13px 24px 13px 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: rgb(246 243 243);
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    border: 2px solid;
  }

  .section-2-contact-details .details-main label {
    color: #7d7d7d;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .contact-input {
    border-radius: 120px;
    background: #f8f8f8;
    align-items: center;
    gap: 321px;
    align-self: stretch;
    border: 1px solid #d6d6d6; /* Define all border properties */
    padding-left: 25px;
    font-family: "Moranga";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    width: 302px;
    height: 40px;
    width:90%;
  }

  .contact-input-textarea {
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    width: 302px;
    height: 224px;
    resize: none;
    width: 84%;
  }
  .contact-input-textarea {
    padding: 20px;
    color: #ccc;
    outline: none;
  }
  .contact-input-textarea::placeholder{
    color: #ccc;
  }
  .section-3-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #687362;
    padding: 60px 24px 60px 24px;
    height: 380px;
  }

  .section-3-topic-details {
    display: flex;
    gap: 30px;
  }

  .section-3-contact .details-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }

  .section-3-contact .topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    padding-bottom: 40px;
  }
  .section-3-contact .details {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .section-3-contact .details-sub {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .section-4-contact {
    background-image: url("../images/image22.png");
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.076) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-4-contact .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }

  .section-4-contact-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
}

/* Medium devices */
@media (max-width: 1024px) {
}

/* Large devices */
@media (max-width: 1200px) {
}

/* Extra large devices */
@media (min-width: 1201px) {
  .section-1-contact {
    /* background-image: url("../images/image19.png"); */
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.076) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-1-contact h1 {
    font-size: 48px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-contact {
    background-color: #f8f7f2;
    display: flex;
    justify-content: center;
    margin: 60px auto;
    padding:unset;
  }
  .section-2-contact-details {
    padding: 80px;
  }

  .section-2-contact-details .details-main {
    display: flex;
    gap: 30px;
    margin: 15px 0px;
  }

  .section-2-contact-details .details-sub {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .section-2-contact .topic {
    color: #25242f;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  }

  .section-2-contact-button {
    margin-top: 20px;
    padding: 13px 24px 13px 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: rgb(246 243 243);
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    border: 2px solid;
  }

  .section-2-contact-details .details-main label {
    color: #7d7d7d;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .contact-input {
    border-radius: 120px;
    background: #ffffff;
    align-items: center;
    gap: 321px;
    align-self: stretch;
    border: 1px solid #d6d6d6; 
    padding-left: 25px;
    font-family: "Moranga";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    width: 444px;
    color: #ccc;
    height: 40px;
  }

  .contact-input-textarea {
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    width: 968px;
    height: 224px;
    resize: none;
    padding: 20px;
    color: #ccc;
    outline: none;
  }
  .contact-input-textarea::placeholder{
    color: #ccc;
    font-family: "Public Sans";
  }

  .section-3-contact {
    display: flex;
    justify-content: space-around;
    background-color: #687362;
    padding: 40px 72px 40px 72px;
    height: 202px;
    align-items: center;
  }

  .section-3-topic-details {
    display: flex;
    gap: 30px;
  }

  .section-3-contact .details-main {
    display: flex;
    gap: 20px;
    align-items: center;
    max-width: fit-content;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 77px;
  }

  .section-3-contact .topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
  }
  .section-3-contact .details {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .section-3-contact .details a {
    color: #ffffff;
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
  }

  .section-3-contact .details-sub {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .section-4-contact {
    background-image: url("../images/image20.png");
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.076) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-4-contact .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }

  .section-4-contact-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
  .section-3-contact-wrapper{
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    gap: 60px;
    justify-content: space-between;
  }
}
@media (min-width:1023px) and (max-width:1199px){
  .section-1-contact {
    background-image: url("../images/image21.png");
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.076) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-1-contact h1 {
    font-size: 48px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-contact {
    background-color: #f8f7f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px;
    width: 85%;
    margin: 60px auto;

  }
  .section-2-contact-details {
    padding: 40px 20px 40px 20px;
  }

  .section-2-contact-details .details-main {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0px;
  }

  .section-2-contact-details .details-sub {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .section-2-contact .topic {
    color: #25242f;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }

  .section-2-contact-button {
    margin-top: 20px;
    padding: 13px 24px 13px 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: rgb(246 243 243);
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    border: 2px solid;
  }

  .section-2-contact-details .details-main label {
    color: #7d7d7d;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .contact-input {
    border-radius: 120px;
    background: #f8f8f8;
    align-items: center;
    gap: 321px;
    align-self: stretch;
    border: 1px solid #d6d6d6; /* Define all border properties */
    padding-left: 25px;
    font-family: "Moranga";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    width: 302px;
    height: 40px;
    width:90%;
  }

  .contact-input-textarea {
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    width: 302px;
    height: 224px;
    resize: none;
    width: 84%;
  }
  .contact-input-textarea {
    padding: 20px;
    color: #ccc;
    outline: none;
  }
  .contact-input-textarea::placeholder{
    color: #ccc;
  }
  .section-3-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #687362;
    padding: 60px 24px 60px 24px;
    height: 380px;
  }

  .section-3-topic-details {
    display: flex;
    gap: 30px;
  }

  .section-3-contact .details-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .section-3-contact .topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }
  .section-3-contact .details {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .section-3-contact .details-sub {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .section-4-contact {
    background-image: url("../images/image22.png");
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.076) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-4-contact .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }

  .section-4-contact-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
}

.contact-input{
  color: #25242f !important;
  font-family: "Public Sans";
}

.contact-input-textarea {
  color: #414141;
  font-family: "Public Sans";
}
.contact-input-textarea::placeholder{
  color: #ccc !important;
  font-family: "Public Sans";
}
.footer-contact-input-textarea {
  color: #414141 !important;
  font-size:14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}
.footer-contact-input-textarea::placeholder {
  font-family: "Public Sans";
  color: #ccc;
  font-size:14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.details-sub img{
  width: 20px;
}
@media (max-width: 768px) {
  .section-3-contact {
      background-color: #687362;
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: flex-start;
      padding: 60px 24px;
      gap: 49px;
  
  }
  .section-3-contact  .details span a{
    color: #fff !important;
    font-family: "Inter", serif;
    font-weight: 400;
  }
}