.body-main {
    margin-top: 94px;
}

.home-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.spc-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.section-1-home {
    padding-top: 90px;
    overflow: hidden;
    margin-top: -90px;
    background: rgb(104, 115, 98);
}

.slider-main-banner-item {
    height: 85vh;
    position: relative;
}

.slider-main-banner-item .text-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    z-index: 2;
    position: relative;
}

.slider-main-banner-item .abs-slider-images {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: #000;
}

.slider-main-banner-item .abs-slider-images img {
    width: 100% !important;
    height: 100%;
    opacity: 0.5;
    object-fit: cover;
}

.slider-main-banner-item .main-name {
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 116.667% */
    width: 55%;
}

.main-banner-slider-btn {
    display: flex;
    width: 225px;
    padding: 13px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    border-radius: 50px;
    border: 2px solid #FFF;
    margin-top: 45px;
    text-decoration: none;
    transition: all 0.5s;
}

.main-banner-slider-btn:hover {
    background: #68736261;
    color: #FFF;
    border: 2px solid #fff;
    transition: all 0.5s;
}

.section-1-home .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 9;
    text-align: center;
    bottom: 60px;
    left: 0;
    /* position: sticky; */
}

.section-1-home .slick-dots li button:before {
    border-radius: 100px;
    color: #D9D9D9;
    width: 12px;
    height: 12px;
    opacity: 1;
}

.section-1-home .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
    border-radius: 100px;
    background: #FFF;
    width: 20px;
    height: 12px;
    margin-top: 4px;
    margin-right: 2px;

}

.section-2-home {
    padding: 80px 0px;
    background: #F8F7F2;
}

.section-2-home-topic {
    color: #3A4445;
    text-align: center;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    padding-bottom: 40px;
}
.section-2-home-topic h3{
    font-size: 32px;
    font-weight: 200;
}
.section-2-home-content-image img {
    height: 50px;
    object-fit: contain;
}

.section-2-home-content-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 5px;
}

.section-2-home-content-1 {
    display: flex;
    gap: 60px;
    justify-content: center;
}

.crd-nme-hm-2 {
    color: #687362;
    text-align: center;
    font-family: "Orelega One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 90.909% */
    margin-bottom: 0;
}

.crd-pra-hm-2 {
    color: #7D7D7D;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    width: 70%;
}


.wrapper-hm-3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    background: #687362;
    height: 570px;
}

.wrapper-hm-3 img {
    height: 570px;
    object-fit: cover;
}

.section-3-home-content {
    padding-left: 136px;
    padding-right: 79px;
}

.section-3-home-content h2 {
    color: #FFF;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px; /* 128.125% */
    margin: 0;
    padding-bottom: 40px;
}

.section-3-home-content p {
    margin: 0;
    color: #FFF;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 40px;
    line-height: 24px; /* 150% */
}

.section-3-home-content .button-hm-3 {
    display: flex;
    width: 150px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #FFF;
    gap: 10px;
    border: none;
    color: #687362;
    text-align: center;
    font-family: "Orelega One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-decoration: none;
}

.section-4-home {
    display: flex;
    padding: 100px 0px;
    gap: 120px;
    align-items: center;
}

.section-4-home-content {
    padding-left: 80px;
}

.section-4-home-content h2 {
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

.tb-btn-hm {
    color: #000;
    display: flex;
    padding: 13px 22px 13px 17px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px !important;
    min-width: 165px;
    margin-right: 0 !important;
    position: relative;
    border: 1px solid #ccc;
}

.devider-hm-tab {
    height: 1px;
    background: #ccc;
    width: 100%;
    margin: 32px 0px;
}

.hm-tab-p {
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
}

.abs-svg-tab-hm {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
}

.flx-bx-btn-hm {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.section-5-home-content {
    padding: 80px 0px;
    background: #F8F7F2;
}

.section-5-home-content-1 {
    display: flex;
    justify-content: space-around;
}

.section-5-home-content-image {
    text-align: center;
}

.section-5-home-content-image h2 {
    color: #687362;
    text-align: center;
    font-family: "Orelega One";
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 66.667% */
    margin: 0;
    padding-bottom: 25px;
}

.section-5-home-content-image p {
    margin: 0;
    color: #687362;
    text-align: center;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 90.909% */
}

.spc-03-cont {
    padding-left: 64px;
    padding-right: 84px;
}

.section-7-home-content {
    border: 2px solid;
    width: 250px;
}

.section-7-home-content-main {
    display: flex;
    justify-content: space-around;
}

.section-8-home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 60px;
}

.section-8-home-content {
    width: 32%;
}

.section-8-home-content h2 {
    margin: 0;
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px; /* 128.125% */
}

.section-8-home-content p {
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.section-8-home-content a {
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-decoration: none;
    display: flex;
    width: 150px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #687362;
}

.section-8-home-image {
    position: relative;
}

.section-8-home-image img {
    height: 325px;
    width: 560px;
    object-fit: cover;
    position: relative;
}

.section-8-home-image svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section-9-home-content {
    display: flex;
    justify-content: space-around;
}

.section-9-home-content-details-topic {
    display: flex;
    align-items: center;
}

.wrapper-hm-3-spc img {
    height: 570px;
    object-fit: cover;
    width: 859px;
}

.section-10-home-image img {
    width: 100%;
    height: 360px;
    object-fit: cover;
}

.section-48-home-image img, .section-48-home-image > iframe {
    display: flex;
    width: 237px;
    height: 188px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.section-48-home-content-details-topic {
    display: flex;
    align-items: center;
    gap: 10px;
}

.section-48-home-content-details-topic p {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    color: #9D9D9D;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 285.714% */
    text-transform: capitalize;
}

.section-48-home-content-details-topic p svg {
    margin-top: 0px;
}

.section-48-home-content-details-topic h3 {
    color: #25242F;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 222.222% */
    text-transform: capitalize;
}

.section-48-home-content-details-topic2 h2 {
    color: #25242F;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 153.846% */
    text-transform: capitalize;
    margin: 0;
    margin-top: -20px;
}

.section-48-home-content-details {
    margin-bottom: 100px;
}

.section-43-home {
    display: flex;
    align-items: flex-start;
    margin: 80px auto;
    justify-content: left;
    gap: 84px;
}

.section-43-home-content h2 {
    margin: 0;
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px; /* 128.125% */
    width: 85%;
}

.section-43-home-testomonial {
    border-radius: 12px;
    background: #F8F7F2;
}

.section-43-home-testomonial p {
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    width: 85%;
    margin: 0 auto;
    margin-bottom: 41px;
    margin-top: 32px;
}

.wprp-test {
    width: 85%;
    margin: 0 auto;
}

.container-testo {
    width: 700px;
}

.wprp-test {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 25px;
}

.wprp-test img {
    height: 68px;
    width: 68px;
    object-fit: cover;
    padding: 4px;
    border: 1.5px solid #687362;
    border-radius: 50%;
}

.wprp-test h5 {
    margin: 0;
    color: #687362;
    font-family: "Orelega One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
}

.wprp-test p {
    margin: 0;
    color: #A6A6A6;
    font-family: "Public Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 184.615% */
    width: 100%;
}

.container-testo .slick-prev, .container-testo .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 70px !important;
    height: 70px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.container-testo .slick-prev {
    left: -500px;
}

.container-testo .slick-next {
    left: -420px;
}

.section-bottom-home-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 700px;
    background-color: #000;
}

.section-bottom-home-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 700px;
    object-fit: cover;
    opacity: 0.7;
}

.section-bottom-home-content .text {
    z-index: 4;
    text-align: center;
    margin-top: -140px;
}

.section-bottom-home-content .text h2 {
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    width: 57%;
    margin: 0 auto;
}

.section-bottom-home-content .text a {
    padding: 9px 24px;
    display: block;
    width: fit-content;
    margin: 0 auto;
    border-radius: 50px;
    background: #FFF;
    color: #25242F;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-transform: capitalize;
    text-decoration: none;
    margin-top: 32px;
}

.green-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 4;
    text-align: center;
    display: flex;
    padding: 50px 0;
    align-items: flex-start;
    gap: 10px;
    background: #687362;
}

.green-bottom span {
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Moranga;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    display: block;
    width: 30%;
    margin: 0 auto;
    font-family: 'Orelega One';
    font-weight: 300;
}
.series-new-image-box .image-ser-sub-item{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    max-height: 300px;
    justify-content: space-between;
    gap: 60px;
    align-items: center;
    padding-bottom: 80px;
}
.series-new-image-box .image-ser-sub-item img{
    width: 50%;
    border-radius: 8px;
    max-height: 300px;
    object-fit: cover;
    height: 300px;
}
.series-new-image-box .image-ser-sub-item p{
    width: 50%;
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    max-height: 300px;
    line-height: 26px;
    overflow-y: auto;
}
.separate-slider .slick-prev, .separate-slider  .slick-next {
    top: -62px  !important;
}
/* .separate-slider .slick-slider {
    position: unset !important;
} */
@media ( max-width: 768px) {
    .slider-main-banner-item .text-section .main-name {
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 117.647% */
        width: 80%;
    }

    .main-banner-slider-btn {
        display: flex;
        padding: 9px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 107.143% */
        width: 30%;
    }

    .section-2-home {
        padding: 60px 25px;
        background: #F8F7F2;
    }

    .section-2-home-topic {
        color: #3A4445;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        padding-bottom: 60px;
        margin-bottom: 0;
    }

    .section-2-home-topic h3 {
        margin: 0;
    }

    .section-2-home-content-image img {
        height: 50px;
        object-fit: contain;
    }

    .section-2-home-content-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 5px;
    }

    .section-2-home-content-1 {
        display: flex;
        gap: 60px;
        justify-content: center;
        flex-direction: column;
    }

    .crd-nme-hm-2 {
        color: #687362;
        text-align: center;
        font-family: "Orelega One";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        margin: 0;
        margin-top: 10px;
    }

    .crd-pra-hm-2 {
        color: #7D7D7D;
        text-align: center;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 0;
        width: 100%;
        margin-top: 0px;
    }

    .wrapper-hm-3 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        background: #687362;
        height: auto;
    }

    .wrapper-hm-3 img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .section-3-home-content {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .section-3-home-content h2 {
        color: #FFF;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        padding-bottom: 40px;
    }

    .section-3-home-content p {
        color: #FFF;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .section-3-home-content .button-hm-3 {
        display: flex;
        width: 150px;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: #FFF;
        gap: 10px;
        border: none;
        color: #687362;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
    }

    .section-4-home {
        display: flex;
        padding: 60px 25px;
        gap: 32px;
        flex-direction: column;
        width: fit-content;
    }

    .section-4-home-image img {
        width: 100%;
    }

    .section-4-home-content h2 {
        color: #25242F;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        margin: 0;
        padding-bottom: 25px;

    }

    .abs-svg-tab-hm {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
    }

    .section-4-home-content {
        padding-left: 0px;
    }

    .flx-bx-btn-hm {
        width: 100%;
    }

    .tb-btn-hm {
        color: #000;
        display: flex;
        padding: 13px 22px 13px 17px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 20px !important;
        min-width: 140px;
    }

    .hm-tab-p {
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .section-5-home-content-1 {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 80px;
    }

    .section-5-home-content-image {
        text-align: center;
    }

    .section-5-home-content-image h2 {
        color: #687362;
        text-align: center;
        font-family: "Orelega One";
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 85.714% */
        padding-bottom: 25px;
    }

    .section-5-home-content-image p {
        margin: 0;
        color: #687362;
        text-align: center;
        font-family: "Public Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */
    }

    .section-5-home-content {
        padding: 80px 25px;
        background: #F8F7F2;
    }

    .wrapper-hm-3-spc img {
        height: 300px;
        object-fit: cover;
        width: 100%;
    }

    .section-8-home {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 40px;

    }

    .section-8-home-content {
        width: 85%;
        margin: 0 auto;

    }

    .section-8-home-content h2 {
        margin: 0;
        color: #25242F;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
    }

    .section-8-home-content p {
        color: #7D7D7D;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .section-8-home-content a {
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        text-decoration: none;
        display: flex;
        width: 150px;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: #687362;
    }

    .section-8-home-image {
        position: relative;
        width: 85%;
        margin: 0 auto;
    }

    .section-8-home-image img {
        width: 100%;
        height: 245px;
        flex-shrink: 0;
        border-radius: 6px;
        object-fit: cover;
        position: relative;
        margin-top: 40px;
    }

    .section-8-home-image svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -10%);
    }

    .section-48-home-content {
        margin-bottom: 40px;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 70px;
    }

    .section-48-home-content-details {
        margin-bottom: 40px;
    }

    .section-48-home-content .slick-dots {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        z-index: 9;
        text-align: center;
        bottom: -15px;
        left: 0;
        /* position: sticky; */
    }

    .section-48-home-content .slick-dots li button:before {
        border-radius: 100px;
        color: #D9D9D9;
        width: 12px;
        height: 12px;
        opacity: 1;
    }

    .section-48-home-content .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #687362;
        border-radius: 100px;
        background: #687362;
        width: 20px;
        height: 12px;
        margin-top: 4px;
        margin-right: 2px;

    }

    .section-10-home-image img {
        width: 100%;
        height: 360px;
        object-fit: cover;
    }

    .section-43-home {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 80px auto;
        justify-content: left;
        gap: 60px;
        width: 85%;
    }

    .section-43-home-content h2 {
        margin: 0;
        color: #25242F;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        width: 100%;
    }

    .section-43-home-testomonial {
        border-radius: 12px;
        background: #F8F7F2;
        margin: 0 0;
    }

    .section-43-home-testomonial p {
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        width: 85%;
        margin: 0 auto;
        margin-bottom: 41px;
        margin-top: 32px;
    }

    .wprp-test {
        width: 85%;
        margin: 0 auto;
    }

    .container-testo {
        width: 100%;
        margin-top: 60px;
    }

    .wprp-test {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 25px;
    }

    .wprp-test img {
        height: 68px;
        width: 68px;
        object-fit: cover;
        padding: 4px;
        border: 1.5px solid #687362;
        border-radius: 50%;
    }

    .wprp-test h5 {
        margin: 0;
        color: #687362;
        font-family: "Orelega One";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
    }

    .wprp-test p {
        margin: 0;
        color: #A6A6A6;
        font-family: "Public Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 184.615% */
        width: 100%;
    }

    .container-testo .slick-prev, .container-testo .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: -15% !important;
        display: block;
        width: 40px !important;
        height: 40px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }

    .container-testo .slick-prev {
        left: 0px !important;
        top: -80px;
    }

    .container-testo .slick-next {
        left: 48px !important;
        top: -80px;
    }

    .section-bottom-home-content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 724px;
        background-color: #000;
    }

    .section-bottom-home-image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        height: 600px;
        object-fit: cover;
        opacity: 0.7;
    }

    .section-bottom-home-content .text {
        z-index: 4;
        text-align: center;
        margin-top: -140px;
    }

    .section-bottom-home-content .text h2 {
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        width: 85%;
        margin: 0 auto;
    }

    .section-bottom-home-content .text a {
        padding: 9px 24px;
        display: block;
        width: fit-content;
        margin: 0 auto;
        border-radius: 50px;
        background: #FFF;
        color: #25242F;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        text-transform: capitalize;
        text-decoration: none;
        margin-top: 32px;
    }

    .green-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 4;
        text-align: center;
        display: flex;
        padding: 50px 0;
        align-items: flex-start;
        gap: 10px;
        background: #687362;
    }

    .green-bottom span {
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        width: 85%;
        margin: 0 auto;
    }
    .series-new-image-box .image-ser-sub-item {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        max-height: 644px;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        padding-bottom: 20px;
    }
    .series-new-image-box .image-ser-sub-item img {
        width: 100%;
        border-radius: 8px;
        max-height: 300px;
        object-fit: cover;
        height: 300px;
    }
    .series-new-image-box .image-ser-sub-item p {
        width: 100%;
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        height: 220px;
        overflow-y: auto;
    }
    .separate-slider .slick-prev, .separate-slider .slick-next {
        top: 39% !important;
    }
}



/* ---------------Adding additional css for home page --------------- */

.section-48-home-image img, .section-48-home-image>iframe {
    align-items: center;
    display: flex;
    height: 234px;
    justify-content: center;
    object-fit: cover;
    width: 100%;
    border-bottom: 1px solid #0000002e;
    padding-bottom: 0;
    background: #000;
    border-radius: 9px;
}

.home-wrapper-to-left .section-3-home-image img{
    opacity: 0;
}
.home-wrapper-to-left .section-4-about-image-abs img{
    left: 0;
    height: 570px;
}
.home-wrapper-to-left .spc-03-cont {
    padding-left: 0;
    padding-right: 0;
}
.home-wrapper-to-left .long-hm-img img{
    width: 862px;
}
.section-8-home .player .vp-video-wrapper {
    border-radius: 9px;
}
.section-48-home-image{
    padding-bottom: 15px;
    border-bottom: 1px solid #00000069;
    width: 90%;
}
.player-3fa8c6d8-c127-495e-b311-bafb4785d007 .vp-video-wrapper {
    background-color: #010101 !important;
    border-radius: 6px;
}
@media(max-width:678px){
    .home-wrapper-to-left .section-4-about-image-abs img {
        left: 0;
        height: 340px;
        top: 0;
    }
    .home-wrapper-to-left .long-hm-img img{
        width:100%;
        height: 300px;
    }
    .duplicate-iframe iframe{
        width: 100%;
    }
    .home-wrapper-to-left .spc-03-cont {
        padding-left: 25px;
        padding-right: 25px;
    }
    
    .section-48-home-image>iframe {
       padding-bottom: 25px;
     }
     .section-2-home-topic h3 {
        font-size: unset;
        font-weight: 200;
    }
    .section-4-home-image img{
        width: 100%;
        height: 260px;
        object-fit: cover;
        border-radius: 12px;
    }
    .coming-soon{
        padding: 50px;
        width:73%;
        border-radius: 6px;
        background: #F8F7F2;
        margin: 0 auto;

    }
    .coming-soon h3{
        color: #687362;
        text-align: center;
        font-family: "Public Sans";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }
    .coming-soon p{
        color: #687362;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    .coming-soon a{
        display: flex;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 50px;
        background: #687362;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        text-decoration: none;
        margin-top: 20px;
    }
    .slider-main-banner-item {
        height: 56vh;
        position: relative;
    }
}
@media (min-width:1024px){
    .section-4-home-image img{
        width: 456px;
    }
    .wrapper-hm-3 {
        height: 100vh;
    }
    .home-wrapper-to-left .section-4-about-image-abs img {
        height: 100vh;
    }
    .section-4-home-image img{
        height: 347px;
        width: 460px;
        object-fit: cover;
        border-radius: 10px;
        margin-top: 18px;
    }
    .section-5-home-content-image {
        text-align: center;
        width: 25%;
    }
 
}


@media (min-width:768px) and (max-width:1000px){
    .section-2-home-content-1{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .section-2-home-content-image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
    }
    .section-4-about-image-abs img {
        bottom:unset;
        top: 0;
        height: 500px;
        left: 0;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
    .section-4-home-image{
        width: 100%;
        display: flex;
    }
    .section-4-home-image img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        object-position: bottom;
        border-radius: 12px;
    }
    .section-5-home-content-1 {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .section-5-home-content-image {
        text-align: center;
        width: 41%;
    }
    .section-8-home {
        gap: 40px;
    }
    .section-8-home-image{
        padding: 20px;
        background: #f8f7f2;
        border-radius: 10px;
    }
    .section-48-home-content {
        width: 92%;
    }
    .section-43-home{
        width: 92%;  
    }
    .home-wrapper-to-left .section-4-about-image-abs img {
        left: 0;
        height: 582px;
    }
    .home-wrapper-to-left .long-hm-img img {
        width: 100%;
    }
    .to-home-specified .section-4-about-image-abs img{
        
        position: absolute;
        bottom: unset;
        top: 0;
        left: 0;
        object-fit: cover;
        height: 500px;
        width: 100%;
        top: 0;
        
    }
    .coming-soon{
        padding: 50px;
        width:60%;
        border-radius: 6px;
        background: #F8F7F2;
        margin: 0 auto;

    }
    .coming-soon h3{
        color: #687362;
        text-align: center;
        font-family: "Public Sans";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 100% */
    }
    .coming-soon p{
        color: #687362;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    .coming-soon a{
        display: flex;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 50px;
        background: #687362;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        text-decoration: none;
        margin-top: 20px;
    }
}
@media (min-width:1023px) and (max-width:1199px){
    .body-main{
        overflow: hidden;
        margin-top: 0 !important;
    }
    .section-2-home-content-1{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .section-2-home-content-image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 32%;
    }
    .section-2-home {
        padding: 80px 25px;
    }
    .section-2-home-content-1{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .section-2-home-content-image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 29%;
    }
    .section-4-about-image-abs img {
        bottom:unset;
        top: 0;
        height: 500px;
        left: 0;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
    .section-4-home-image{
        width: 100%;
        display: flex;
    }
    .section-4-home-image img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        object-position: bottom;
        border-radius: 12px;
    }
    .section-5-home-content-1 {
        display: flex;
        flex-direction: row;
        gap: 82px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .section-5-home-content-image {
        text-align: center;
        width: 33%;
    }
    .section-8-home {
        gap: 40px;
    }
    .section-8-home-image{
        padding: 20px;
        background: #f8f7f2;
        border-radius: 10px;
    }
    .section-48-home-content {
        width: 92%;
    }
    .section-43-home{
        width: 92%;  
    }
    .home-wrapper-to-left .section-4-about-image-abs img {
        left: 0;
        height: 582px;
    }
    .home-wrapper-to-left .long-hm-img img {
        width: 100%;
    }
    .section-2-home-topic {
        width: 70%;
    }
    .section-4-about-image-abs{
        position: relative;
    }
    .wrapper-hm-3-spc img {
        height: 570px;
        object-fit: cover;
        width: 577px;
    }
    .wrapper-hm-3 {
        height: 75.6vh;
    }    
    .home-wrapper-to-left .long-hm-img img {
        width: 45%;
        top: -582px;
    }
    .section-4-about-image-abs img {
        bottom: unset;
        top: -582px;
        height: 500px;
        left: 0;
        object-fit: cover;
        position: absolute;
        width: 45%;
        object-position: center;
    }
    .wrapper-hm-3 img {
        height: 534px;
        object-fit: cover;
    }
    .section-3-home-content {
        padding-left: 0;
        padding-right: 26px;
    }
    .section-3-home .wrapper-hm-3{
        height: 99vh;
    }
    .home-wrapper-to-left .section-4-about-image-abs img {
        left: 0;
        height: 757px;
    }
    .section-4-about-image-abs img {
        top: -759px;
    }
    .home-wrapper-to-left .long-hm-img img {
        width: 45%;
        top: -760px;
        height: 760px !important;
    }
    .section-4-home {
        display: flex;
        padding: 100px 30px;
        gap: 57px;
    }
    .section-4-home-content {
        padding-left: 0px;
    }
    .section-2-price-details .duration{
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
    }
    .section-2-price-details{
        text-align: center;
    }
    .section-8-home {
        gap: 0px;
        padding: 30px;
    }
    .section-8-home-content {
        width: 42%;
    }
    .main-container-footer{
        padding: 0 30px;
    }
    .main-container-footer .section-main {
        padding: 80px 0 !important;
    }
    .main-intro-footer {
        width: 67%;
    }
    .coming-soon{
        padding: 50px;
        width:40%;
        border-radius: 6px;
        background: #F8F7F2;
        margin: 0 auto;

    }
    .coming-soon h3{
        color: #687362;
        text-align: center;
        font-family: "Public Sans";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 100% */
    }
    .coming-soon p{
        color: #687362;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    .coming-soon a{
        display: flex;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 50px;
        background: #687362;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        text-decoration: none;
        margin-top: 20px;
    }
}

@media (min-width:1200px){
    .inner-cont{
        display: flex;
        gap: 60px;
        align-items: center;
        background: #687362;
        margin: 100px 0px ;
        border-radius: 12px;

    }
    .child-inner-div{
        width:50%;
        background: #687362;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    .child-inner-div img{
        width:100%;
        min-height: 500px;
        object-fit: cover;
        margin: 0;
        margin-bottom: -4px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    .child-inner-div h2{
        color: #fff;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding-bottom: 40px;
    font-family: Orelega One;
    font-size: 32px;
    line-height: 41px;
    width: 80%;
    }
    .child-inner-div p{
        font-family: Public Sans;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding-bottom: 40px;
    width: 90%;
    }
    .child-inner-div a{
        align-items: center;
    background: #fff;
    border: none;
    border-radius: 50px;
    color: #687362;
    display: flex;
    font-family: Orelega One;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    gap: 10px;
    justify-content: center;
    line-height: 22px;
    padding: 9px 24px;
    text-align: center;
    text-decoration: none;
    width: 150px;
    }
    .about-page .inner-cont {
        align-items: center;
    } 
    .about-page .section-4-topic{
        margin-top: 0 !important;
    }
    .list-div{
        padding: 0;
    }
    .about-4spc-wrapper .section-4-topic-list {
        padding-left: 40px;
        padding-right: 0px;
        padding-bottom: 0;
        padding-top: 0;
    }
    .coming-soon{
        padding: 50px;
        width:40%;
        border-radius: 6px;
        background: #F8F7F2;
        margin: 0 auto;

    }
    .coming-soon h3{
        color: #687362;
        text-align: center;
        font-family: "Public Sans";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 100% */
        margin-top: 0;
    }
    .coming-soon p{
        color: #687362;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    .coming-soon a{
        display: flex;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 50px;
        background: #687362;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        text-decoration: none;
        margin-top: 20px;
    }
    .banner-h3{
        width: 60%;
        margin: 0 auto;
    }
    .section-8-home-image.duplicate-iframe iframe{
        padding: 20px;
        background: #f8f7f2;
        border-radius: 12px;
    }

}


@media (max-width:1100px){
    .inner-cont{
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        background: #687362;
       
        border-radius: 0;


    }
    .inner-cont:first-child{
        padding: 0;
    }
    .inner-cont .last{
       width: 85%;
       margin: 0 auto;
       padding-bottom: 60px;
    }
    .child-inner-div{
        width:100%;
        background: #687362;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .child-inner-div img{
        width:100%;
        height: 300px;
        object-fit: cover;
        margin: 0;
        margin-bottom: -4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .child-inner-div h2{
        color: #fff;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding-bottom: 40px;
    font-family: Orelega One;
    font-size: 32px;
    line-height: 41px;
    width: 100%;
    }
    .child-inner-div p{
        font-family: Public Sans;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding-bottom: 40px;
    width: 100%;
    }
    .child-inner-div a{
        align-items: center;
    background: #fff;
    border: none;
    border-radius: 50px;
    color: #687362;
    display: flex;
    font-family: Orelega One;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    gap: 10px;
    justify-content: center;
    line-height: 22px;
    padding: 9px 24px;
    text-align: center;
    text-decoration: none;
    width: 150px;
    }
}
.player.right-content-area-supported{
    border-radius: 20px !important;
}