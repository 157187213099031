.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

button {
    cursor: pointer;
}

.alert {
    margin-top: 2%;
    font-size: 15px;
    color: #25242F;
    text-align: center;
    align-content: center;
    width: 100%;
    height: 40px;
    border-radius: 50px;
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}

.alert-danger {
    background-color: #FCF2F2;
    border: 1px solid #FFBDBD;
}

.alert-success {
    background-color: #E6FCF2;
    border: 1px solid #B4DEBD;
}

.spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0;
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
    .spinner-grow {
        --bs-spinner-animation-speed: 1.5s;
    }
}

@keyframes spinner-grow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

button > .spinner-grow {
    margin-right: 1%;
}

.textarea-description {
    white-space: pre-line;
}

.text-editor {
    padding: 5%;
    border: 1px solid #D0D5DD !important;
    border-radius: 4px !important;
    background-color: transparent !important;
}

.codex-editor__redactor {
    padding-bottom: 0 !important;
}

.ce-block__content, .ce-toolbar__content {
    max-width: 100% !important;
}

.image-tool__caption {
    display: none;
}

.image-tool__image-picture {
    width: 100%;
}

.codex-editor--narrow .codex-editor__redactor {
    margin-right: 0 !important;
}

.image-uploader {
    border: 2px dashed #cccccc;
    padding: 10px;
    text-align: center;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    cursor: pointer;
}

.image-upload-box {
    height: 75px;
    width: 75px;
    object-fit: cover;
}

.upload-image-preview {
    height: 75px;
    width: 75px;
    object-fit: cover;
}

.preview-image-remove {
    position: absolute;
    top: 20%;
    color: white;
    padding: 5px 10px;
    border-radius: 18px;
}

.btn-image-remove {
    position: absolute;
    top: -35px;
    right: -100px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    background-color: #ff4d4d0f;
    color: white;
    cursor: pointer;
}
