/* Base styles - Mobile first */
.toggle-switch {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: #25242F ;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch.on {
  background-color: #687362;
}

.toggle-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}

.toggle-switch.on .toggle-thumb {
  transform: translateX(25px);
}

/* Small devices */
@media (max-width: 768px) {
  .section-1-price {
    background-size: cover;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-1-price h1 {
    font-size: 48px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-price {
    background-color: #f8f7f2;
    padding: 20px 10px 00px 10px;
    gap: 60px;
  }
  .section-2-price-content {
    border: 2px solid;
    width: 250px;
  }

  .section-2-price-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-2-price-details h1 {
    font-size: 30px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    text-align: center;
  }

  .section-2-price-details p {
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .section-2-price-content p {
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    gap: 12px;
  }

  .section-2-price-details .duration {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .section-2-price-details .duration h4 {
    font-size: 20px;
    font-family: "Orelega One", serif;
    font-weight: 300;
    font-style: normal;
    line-height: 24px;
    color: #7d7d7d;
  }
  .section-2-price-content-main {
    display: flex;
    justify-content: space-around;
    gap: 22px;
    padding: 36px 0;
  }

  .section-2-price-content-sub :hover {
    background-color: #687362;
  }

  .section-2-price-content-sub:hover .subscribe {
    background-color: #ffffff;
    color: #25242f;
  }

  .section-2-price-content-sub h2 {
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -3%;
  }

  .section-2-price-content-sub .price-tag {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }

  .section-2-price-content-sub:hover h2 {
    color: #ffffff;
  }

  .section-2-price-content-sub:hover p {
    color: #ffffff;
  }
  .section-2-price-content-sub:hover img {
    filter: invert(1);
  }

  .section-2-price-content-main .subscribe {
    background-color: #687362;
    color: #ffffff;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    width: 100%;
  }

  .section-2-price-content {
    border-radius: 6px;
    border: 1px solid;
    padding: 50px 20px;
    display: block;
    width: 85%;
    margin: 0 auto;
  }

  .section-3-price {
    background-size: cover;
    width: 100%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-3-price .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 35px;
  }

  .section-3-price-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }

  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
    width: 85%;
    margin: 0 auto;
  }
}

/* Medium devices */
@media (max-width: 1024px) {
}

/* Large devices */
@media (max-width: 1200px) {
}

/* Extra large devices */
@media (min-width: 1201px) {
  .section-2-price .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: block;
  }

  .section-1-price {
    background-image: url("../images/image13.png");
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.076) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-1-price h1 {
    font-size: 48px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-price {
    background-color: #f8f7f2;
    padding: 0 10px;
    gap: 60px;
  }
  .section-2-price-content {
    border: 2px solid;
    width: 250px;
  }

  .section-2-price-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-2-price-details h1 {
    font-size: 32px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }

  .section-2-price-details p {
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 456px;
  }

  .section-2-price-content p {
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .section-2-price-details .duration {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .section-2-price-details .duration h4 {
    font-size: 20px;
    font-family: "Orelega One", serif;
    font-weight: 300;
    font-style: normal;
    line-height: 24px;
    color: #7d7d7d;
  }
  .section-2-price-content-main {
    display: flex;
    justify-content: space-around;
    gap: 22px;
  }

  .section-2-price-content-sub :hover {
    background-color: #687362;
  }

  .section-2-price-content-sub:hover .subscribe {
    background-color: #ffffff;
    color: #25242f;
  }

  .section-2-price-content-sub h2 {
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -3%;
    letter-spacing: -3%;
  }

  .section-2-price-content-sub .price-tag {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }

  .section-2-price-content-sub:hover h2 {
    color: #ffffff;
  }

  .section-2-price-content-sub:hover p {
    color: #ffffff;
  }
  .section-2-price-content-sub:hover img {
    filter: invert(1);
  }

  .section-2-price-content-main .subscribe {
    background-color: #687362;
    color: #ffffff;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    width: 100%;
  }

  .section-2-price-content {
    border-radius: 6px;
    border: 1px solid;
    padding: 50px 35px 50px 35px;
    width: 320px;
    min-height: 480px;
  }

  .section-3-price {
    background-image: url("../images/image14.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-3-price .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }

  .section-3-price-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
  .mobile-view {
    display: none;
  }
}


/* -------- Adding additional CSS to pricing cards ---------------------- */
@media (min-width: 1201px) {
  .section-2-price-content-main {
      display: flex;
      gap: 30px;
      justify-content: center;
  }
  .section-2-price-content {
    min-height: 470px;
    cursor: grab;
  }
  .section-2-price-content {
    border: 1px solid #000;
  }
  .section-2-price-content:hover {
    border: 1px solid #687362;
  }
}
@media (max-width: 768px) {
  .section-2-price-content {
      min-height: 470px;
  }
}
@media (min-width: 769px) and (max-width: 1199px) {
  .section-1-price {
    background-size: cover;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-1-price h1 {
    font-size: 48px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-price {
    background-color: #f8f7f2;
    padding: 20px 10px 00px 10px;
    gap: 60px;
  }
  .section-2-price-content {
    border: 2px solid;
    width: 250px;
  }

  .section-2-price-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-2-price-details h1 {
    font-size: 30px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    text-align: center;
  }

  .section-2-price-details p {
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .section-2-price-content p {
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    gap: 12px;
  }

  .section-2-price-details .duration {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .section-2-price-details .duration h4 {
    font-size: 20px;
    font-family: "Orelega One", serif;
    font-weight: 300;
    font-style: normal;
    line-height: 24px;
    color: #7d7d7d;
  }
  .section-2-price-content-main {
    display: flex;
    justify-content: space-around;
    gap: 22px;
    padding: 36px 0;
  }

  .section-2-price-content-sub :hover {
    background-color: #687362;
  }

  .section-2-price-content-sub:hover .subscribe {
    background-color: #ffffff;
    color: #25242f;
  }

  .section-2-price-content-sub h2 {
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -3%;
  }

  .section-2-price-content-sub .price-tag {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }

  .section-2-price-content-sub:hover h2 {
    color: #ffffff;
  }

  .section-2-price-content-sub:hover p {
    color: #ffffff;
  }
  .section-2-price-content-sub:hover img {
    filter: invert(1);
  }

  .section-2-price-content-main .subscribe {
    background-color: #687362;
    color: #ffffff;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    width: 100%;
  }

  .section-2-price-content {
    border-radius: 6px;
    border: 1px solid;
    padding: 50px 20px;
    display: block;
    width: 85%;
    margin: 0 auto;
  }

  .section-3-price {
    background-size: cover;
    width: 100%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-3-price .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 35px;
  }

  .section-3-price-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 12px 18px 12px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }

  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
    width: 85%;
    margin: 0 auto;
  }
}
.player.right-content-area-supported{
  border-radius: 20px !important;
}
