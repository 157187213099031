.profile-user-setting-main-wrapper .main-name{
    display: block;
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    text-transform: capitalize; 
}
.profile-user-setting-main-wrapper .sub-name{
    display: block;
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.profile-user-setting-main-wrapper{
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #D6D6D6;
    width: 100%;
}
.uerSetting-personal-section{
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    gap: 130px;
}
.uerSetting-personal-section .left-side-us{
    width: 35%;
}
 .uerSetting-personal-section .right-side-us{
    width: 75%;
}
.uerSetting-personal-section .left-side-us .main-personal-ttl{
    color: #25242F;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    text-transform: capitalize;
}
.uerSetting-personal-section .left-side-us .sub-personal-ttl{
    display: block;
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */

}
.uerSetting-personal-section .right-side-us .double-div-class{
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items:flex-start;
    margin-bottom: 40px;
}
.uerSetting-personal-section .right-side-us .double-div-class > div{
    width: 50%;
}
.uerSetting-personal-section .right-side-us .us-form-label{
    color: #25242F;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: block;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
}
.uerSetting-personal-section .right-side-us .double-div-class input{
    width:88%;
    outline: none;
    border: 1px solid;
    display: flex;
    padding: 15px 24px;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #D6D6D6;
    background: #FFF;
}
.uerSetting-personal-section .right-side-us input{
    width:95%;
    outline: none;
    border: 1px solid;
    display: flex;
    padding: 15px 24px;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    color: #9D9D9D;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 171.429% */
}
.uerSetting-personal-section .right-side-us input:focus{
    border: 1px solid #D6D6D6;
}
.wrapper-icon-div-input{
    position: relative;
    margin-bottom: 40px;
}
.wrapper-icon-div-input svg{
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
}
.flex-user-photo{
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    margin-bottom: 40px;
}
.flex-user-photo img{
    height: 75px;
    width: 75px;
    border-radius: 50%;

}
.gradient-user-photo{
    border-radius: 8px;
    background: linear-gradient(135deg, #9BB7C5 0%, #527A6A 100%);
    height: 74px;
    flex: 1 0 0;
}
.submit-buttons-user-data{
    display: flex;
    align-items: center;
    gap: 24px;
}
.submit-buttons-user-data .cancel-update{
    display: flex;
    width: 188px;
    padding: 11px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #687362;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-transform: capitalize;
    border-radius: 50px;
    border: 1px solid #687362;
}
.submit-buttons-user-data .do-update{
    display: flex;
    width: 188px;
    padding: 11px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #687362;
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-transform: capitalize;
    border: 1px solid #687362;
}
.sec-2-user-settings{
    margin-top: 40px ;
    padding-top: 40px;
    border-top: 1px solid #D6D6D6;
    margin-bottom: 40px;
}
.right-side-us .faq-question{
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px ;
    margin-bottom: 24px ;
    border-bottom: 1px solid #D6D6D6;
    cursor: pointer;
}
.right-side-us .last-fq{
    border-bottom: none;
}
.right-side-us .faq-answer{
    color: #9D9D9D;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */ 
    padding-bottom: 24px ;   border-bottom: 1px solid #D6D6D6;
    margin-bottom: 24px;
}

@media (max-width:768px){
    .profile-user-setting-main-wrapper .main-name{
        color: #25242F;
        font-family: "Orelega One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 115.385% */
        text-transform: capitalize;
    }
    .profile-user-setting-main-wrapper .sub-name{
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    
    .profile-user-setting-main-wrapper{
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #D6D6D6;
        width: 85%;
        margin: 0 auto;
    }
    .uerSetting-personal-section{
        display: flex;
        align-items: flex-start;
        justify-content: left;
        flex-direction: column;
        width: 85%;
        gap: 40px;
        margin: 0 auto;
        margin-top: 40px;
    }
    .uerSetting-personal-section .left-side-us{
        width: 100%;
    }
     .uerSetting-personal-section .right-side-us{
        width: 100%;
    }
    .uerSetting-personal-section .left-side-us .main-personal-ttl{
        color: #25242F;
        font-family: "Orelega One";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 166.667% */
        text-transform: capitalize;
    }
    .uerSetting-personal-section .left-side-us .sub-personal-ttl{
        display: block;
        color: #7D7D7D;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    
    }
    .uerSetting-personal-section .right-side-us .double-div-class{
        display: flex;
        justify-content: space-between;
        gap: 24px;
        align-items:flex-start;
        flex-direction: column;
        margin-bottom: 24px;
    }
    .uerSetting-personal-section .right-side-us .double-div-class > div{
        width:100%;
    }
    .uerSetting-personal-section .right-side-us .us-form-label{
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 2px;
    }
    .uerSetting-personal-section .right-side-us .double-div-class input{
        width:88%;
        outline: none;
        border: 1px solid;
        display: flex;
        padding: 15px 24px;
        align-items: center;
        align-self: stretch;
        border-radius: 120px;
        border: 1px solid #D6D6D6;
        background: #FFF;
    }
    .uerSetting-personal-section .right-side-us input{
        width:84%;
        outline: none;
        border: 1px solid;
        display: flex;
        padding: 15px 24px;
        align-items: center;
        align-self: stretch;
        border-radius: 120px;
        border: 1px solid #D6D6D6;
        background: #FFF;
        color: #9D9D9D;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 171.429% */
    }
    .uerSetting-personal-section .right-side-us input:focus{
        border: 1px solid #D6D6D6;
    }
    .wrapper-icon-div-input{
        position: relative;
        margin-bottom: 40px;
    }
    .wrapper-icon-div-input svg{
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
    }
    .flex-user-photo{
        display: flex;
        justify-content: flex-start;
        gap: 24px;
        margin-bottom: 40px;
    }
    .flex-user-photo img{
        height: 75px;
        width: 75px;
        border-radius: 50%;
    
    }
    .gradient-user-photo{
        border-radius: 8px;
        background: linear-gradient(135deg, #9BB7C5 0%, #527A6A 100%);
        height: 74px;
        flex: 1 0 0;
    }
    .submit-buttons-user-data{
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .submit-buttons-user-data .cancel-update{
        display: flex;
        width: 188px;
        padding: 11px 18px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #687362;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        text-transform: capitalize;
        border-radius: 50px;
        border: 1px solid #687362;
    }
    .submit-buttons-user-data .do-update{
        display: flex;
        width: 188px;
        padding: 12px 11px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: #687362;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-transform: capitalize;
        border: 1px solid #687362;
    }
    .sec-2-user-settings{
        margin-top: 40px ;
        padding-top: 40px;
        border-top: 1px solid #D6D6D6;
        margin-bottom: 40px;
    }
    .right-side-us .faq-question{
        color: #7D7D7D;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px ;
        margin-bottom: 24px ;
        border-bottom: 1px solid #D6D6D6;
        cursor: pointer;
    }
    .right-side-us .last-fq{
        border-bottom: none;
    }
    .right-side-us .faq-answer{
        color: #9D9D9D;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */ 
        padding-bottom: 24px ;   border-bottom: 1px solid #D6D6D6;
        margin-bottom: 24px;
    }
}