/* Base styles - Mobile first */

/* Small devices */
@media (max-width: 768px) {

  .section-1-about-home {
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;
  }

  .section-2-about {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #687362;
    padding: 65px 28px 65px 28px;
    gap: 15px;
  }

  .section-2-about .topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }
  .section-2-about .description {
    color: #ffffff;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    punctuation: hanging;
  }

  .section-3-about {
    display: flex;
    flex-direction: column;
    background-color: #f8f7f2;
    padding: 60px 24px 60px 24px;
    justify-content: space-between;
    gap: 0px;
  }

  .section-3-about-image img {
    width: 342px;
    height: 335px;
    border-radius: 12px;
    object-fit: cover;
  }
  .section-3-about h1 {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #25242f;
  }
  .section-3-description {
    font-family: sans-serif;
    color: #7d7d7d;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }

  .section-3-about-button {
    margin-top: 20px;
    padding: 13px 24px 13px 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: rgb(246 243 243);
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    border: 2px solid;
  }

  .section-4-about {
    display: flex;
    flex-direction: column;
    background-color: #687362;
    padding: 18px -1px 18px 0px;
  }
  .section-4-topic-list {
    padding: 0px 20px;
  }
  .section-4-topic h5 {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400 ;
    font-size: 32px;
    line-height: 41px;
  }

  .section-4-list {
    color: #ffffff;
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
  .section-4-list span {
    display: flex;
    align-items: flex-start;

    gap: 5px;
  }
  .section-4-about-image img {
    width: 100%;
    height: 559px;
  }
  .vision-mission-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding: 60px 24px 60px 24px;
    gap: 30px;
  }

  .vision-mission-main hr {
    border: none;
    height: 1px;
    background-color: #d6d6d6;
    margin: 20px 0;
  }

  .vision-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    padding: 10px 10px 35px 10px;
    border-radius: 12px;
    background-color: #f8f7f2;
  }
  .vision-main .vision {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #25242f;
  }

  .vision-details {
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7d7d7d;
  }

  .mission-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    padding: 10px 10px 35px 10px;
    border-radius: 12px;
    background-color: #687362;
  }

  .mission-main .mission {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #ffffff;
  }

  .mission-details {
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .section-6-about {
    background-image: url("../images/image18.png");
    background-size: contain;
    width: 100%;
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }

  .section-6-about .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }

  .section-6-about-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 4px 18px 8px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
}

/* Medium devices */
@media (max-width: 1024px) {
  .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }

  .section-1-about {
    background-size: cover;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-1-about h1 {
    font-size: 48px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-about {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #687362;
    padding: 65px 28px 65px 28px;
    gap: 15px;
  }

  .section-2-about .topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }
  .section-2-about .description {
    color: #ffffff;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    punctuation: hanging;
  }

  .section-3-about {
    display: flex;
    flex-direction: column;
    background-color: #f8f7f2;
    padding: 60px 24px 60px 24px;
    justify-content: space-between;
    gap: 0px;
  }

  .section-3-about-image img {
    width: 342px;
    height: 335px;
    border-radius: 12px;
    object-fit: cover;
  }
  .section-3-about h1 {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #25242f;
  }
  .section-3-description {
    font-family: sans-serif;
    color: #7d7d7d;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }

  .section-3-about-button {
    margin-top: 20px;
    padding: 13px 24px 13px 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: rgb(246 243 243);
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    border: 2px solid;
  }

  .section-4-about {
    display: flex;
    flex-direction: column;
    background-color: #687362;
    padding: 18px -1px 18px 0px;
  }
  .section-4-topic-list {
    padding: 0px 20px;
  }
  .section-4-topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
  }

  .section-4-list {
    color: #ffffff;
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
  .section-4-list span {
    display: flex;
    align-items: flex-start;

    gap: 5px;
  }
  .section-4-about-image img {
    width: 100%;
    height: 559px;
  }
  .vision-mission-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding: 60px 24px 60px 24px;
    gap: 30px;
  }

  .vision-mission-main hr {
    border: none;
    height: 1px;
    background-color: #d6d6d6;
    margin: 20px 0;
  }

  .vision-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    padding: 10px 10px 35px 10px;
    border-radius: 12px;
    background-color: #f8f7f2;
  }
  .vision-main .vision {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #25242f;
  }

  .vision-details {
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7d7d7d;
    font-family: sans-serif !important;
}

  .mission-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    padding: 10px 10px 35px 10px;
    border-radius: 12px;
    background-color: #687362;
  }

  .mission-main .mission {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #ffffff;
  }

  .mission-details {
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    font-family: sans-serif !important;
    }
  .section-6-about {
    background-size: contain;
    width: 100%;
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }

  .section-6-about .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }

  .section-6-about-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 4px 18px 8px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
}

/* Large devices */
@media (max-width: 1200px) {
  .section-1-about .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 175px;
  }

  .section-1-about {
    background-size: cover;
    width: 100%;
    height: 512px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-2-about {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #687362;
    padding: 65px 28px 65px 28px;
    gap: 15px;
  }

  .section-2-about .topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }
  .section-2-about .description {
    color: #ffffff;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .section-3-about {
    display: flex;
    flex-direction: column;
    background-color: #f8f7f2;
    padding: 60px 24px 60px 24px;
    justify-content: space-between;
    gap: 0px;
  }

  .section-3-about-image img {
    width: 342px;
    height: 335px;
    border-radius: 12px;
    object-fit: cover;
  }
  .section-3-about h1 {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #25242f;
  }
  .section-3-description {
    font-family: sans-serif;
    color: #7d7d7d;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }

  .section-3-about-button {
    margin-top: 20px;
    padding: 13px 24px 13px 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: rgb(246 243 243);
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    border: 2px solid;
  }

  .section-4-about {
    display: flex;
    flex-direction: column;
    background-color: #687362;
    padding: 18px -1px 18px 0px;
  }
  .section-4-topic-list {
    padding: 0px 20px;
  }
  .section-4-topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
  }

  .section-4-list {
    color: #ffffff;
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
  .section-4-list span {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    font-family: sans-serif;
}
  .section-4-about-image img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .vision-mission-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding: 60px 24px 60px 24px;
    gap: 30px;
  }

  .vision-mission-main hr {
    border: none;
    height: 1px;
    background-color: #d6d6d6;
    margin: 20px 0;
  }

  .vision-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    padding: 10px 10px 35px 10px;
    border-radius: 12px;
    background-color: #f8f7f2;
  }
  .vision-main .vision {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #25242f;
  }

  .vision-details {
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7d7d7d;
  }

  .mission-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    padding: 10px 10px 35px 10px;
    border-radius: 12px;
    background-color: #687362;
  }

  .mission-main .mission {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #ffffff;
  }

  .mission-details {
    font-family: "public-sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .section-6-about {
    background-size: contain;
    width: 100%;
    height: 462px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }

  .section-6-about .topic {
    font-size: 26px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 30px;
  }

  .section-6-about-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 4px 18px 8px 18px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
}

/* Extra large devices */
@media (min-width: 1201px) {
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 115px;

  }

   .section-2-about {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #687362;
    padding: 66px 72px 66px 72px;
    height: 202px;
    align-items: center;
  }

  .section-2-about .topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
  }
  .section-2-about .description {
    color: #ffffff;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .section-3-about {
    display: flex;
    flex-direction: row;
    background-color: #f8f7f2;
    padding: 80px 156px 80px 156px;
    justify-content: space-between;
    gap: 130px;
  }
  .section-3-about h1 {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #25242f;
  }
  .section-3-description {
    font-family: sans-serif;
    color: #7d7d7d;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
  .section-3-about-button {
    margin-top: 20px;
    padding: 13px 24px 13px 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: rgb(246 243 243);
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
    border: 2px solid;
  }
  .section-4-about {
    display: flex;
    flex-direction: row;
    background-color: #687362;
  }
  .section-4-topic-list {
    padding: 80px 130px;
  }
  .section-4-topic {
    color: #ffffff;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
  }

  .section-4-list {
    color: #ffffff;
    font-family: "sans-serif";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
  .section-4-list span {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    font-family: sans-serif;
}

  .section-4-about-image img {
    width: 700px;
    height: 555px;
  }

  .vision-mission-main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding: 80px 0px 80px 0px;
    gap: 50px;
  }

  .vision-mission-main hr {
    border: none;
    height: 1px;
    background-color: #d6d6d6;
    margin: 20px 0;
  }

  .vision-main {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 175px;
    padding: 60px 40px 60px 40px;
    background-color: #f8f7f2;
    border-radius: 12px;
  }
  .vision-main .vision {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #25242f;
  }

  .vision-details {
    font-family: "sans-serif";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7d7d7d;
  }

  .mission-main {
    display: flex;
    flex-direction: column;
    width: 545px;
    height: 175px;
    padding: 60px 40px 60px 40px;
    background-color: #687362;
    border-radius: 12px;
  }

  .mission-main .mission {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #ffffff;
  }

  .mission-details {
    font-family: "sans-serif";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .section-6-about {
    background-image: url("../images/image12.png");
    background-size: cover;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
  }

  .section-6-about .topic {
    font-family: Orelega One, serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
  }

  .section-6-about-button {
    background-color: #ffffff;
    color: #25242f;
    border: none;
    margin-top: 20px;
    padding: 15px 35px 15px 35px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 50px;
  }
}


/* -------- Adding additional CSS to section containers ---------------------- */

@media (min-width: 1201px) {
  .section-2-left {
    width: 50%;
  }

  .section-2-right {
    width: 50%;
  }
  .about-4spc-wrapper{
    background-color: #687362;
    position: relative;
  }
  .about-4spc-wrapper .section-4-topic-list {
    padding-left: 0;
    padding-right: 40px;
    padding-bottom: 0;
    padding-top: 40px;
  }
  .about-4spc-wrapper .section-4-about-image img {
    opacity: 0;
  }
  .section-4-about-image-abs img{
    position: absolute;
    top: 0;
    right: 0;
    width: 700px;
    height: 555px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .conent-gap {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .about-4spc-wrapper{
    background-color: #687362;
    position: relative;
  }
  .about-4spc-wrapper .section-4-topic-list {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .about-4spc-wrapper .section-4-about-image img {
    opacity: 0;
  }
  .section-4-about-image-abs img{
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 500px;
    width: 100%;
  }
}

@media (min-width:768px) and (max-width:1000px){
  .section-4-about-image-abs img {
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 500px;
    width: 100%;
    top: unset;
  }
}
@media (min-width:1023px) and (max-width:1199px){
  .contact-main .section-4-about-image-abs img {
    top: -759px;
    display: none;
  }
  .vision-mission-main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding: 60px 24px 60px 24px;
    gap: 30px;
  }
}
@media (min-width:1200px){
  .inner-cont{
      display: flex;
      gap: 60px;
      align-items: center;
      background: #687362;
      margin: 100px 0px ;
      border-radius: 12px;

  }
  .child-inner-div{
      width:47%;
      background: #687362;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
  }
  .child-inner-div img{
      width:100%;
      min-height: 430px;
      object-fit: cover;
      margin: 0;
      margin-bottom: -4px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
  }
  .child-inner-div h2{
      color: #fff;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding-bottom: 40px;
  font-family: Orelega One;
  font-size: 32px;
  line-height: 41px;
  width: 80%;
  }
  .child-inner-div p{
      font-family: Public Sans;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding-bottom: 40px;
  width: 90%;
  }
  .child-inner-div a{
      align-items: center;
  background: #fff;
  border: none;
  border-radius: 50px;
  color: #687362;
  display: flex;
  font-family: Orelega One;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  gap: 10px;
  justify-content: center;
  line-height: 22px;
  padding: 9px 24px;
  text-align: center;
  text-decoration: none;
  width: 150px;
  }
  .about-page{
    margin: 100px auto 20px;
    border-radius: 12px;
  }
  .about-page .inner-cont {
    align-items: center;
  }
  .about-page .section-4-topic h5{
      margin-top: 0 !important;
  }
  .about-4spc-wrapper .section-4-topic-list {
    padding-left: 40px;
    padding-right: 0px;
    padding-bottom: 0;
    padding-top: 0;
 }
 .about-page .child-inner-div{
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
 }
 .about-page .inner-cont img{
  border-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
 }
 .spc-child-inner-div{
  padding: 60px 0px;
  }
  .hm-child-inner-div img {
    width: 100%;
    height: 750px;
    object-fit: cover;
    margin: 0;
    margin-bottom: -4px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .about-page .child-inner-div {
    width: 85%;
    background: #687362;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
}
@media (max-width:1100px){
  .about-page .section-4-topic h5{
    margin-top: 20px !important;
  }
  .about-page .section-4-topic h5{
    margin-bottom: 40px !important;
  }
  .about-4spc-wrapper .section-4-topic-list {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 00px;
    padding-top: 40px;
  }
  .spc-child-inner-div {
    padding: 0 0 40px;
    width: 85%;
    margin: 0 auto;
}
}

@media (min-width: 1201px) {
    .vision-details, .mission-details {
        font-family: sans-serif !important;
    }
}
