.main-container-footer{
  background: #F8F7F2;
}
.main-container-footer .section-main{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 48px;
  border-bottom: 1px solid #D6D6D6;
  padding: 80px 0;
 
}
.container-footer{
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}
.main-intro-footer {
  color: #7D7D7D;
  font-family: 'Public Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 24px;
  width: 95%;
  margin: 0;
  margin-right: 0;
  
}
.main-container-footer .section-main2{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 48px;
  border-bottom: 1px solid #D6D6D6;
  padding-bottom: 48px;
}
.main-container-footer .section-main3{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 24px;
  border-bottom: 0px solid #D6D6D6;
  padding-bottom: 80px;
}

.main-container-footer .section-2 h5{
    color: #25242F;
    font-family: "Orelega One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    margin: 0;
}
.main-container-footer .section-2 ul{
  list-style: none;
  padding-left: 0;
}
.main-container-footer .section-2 ul a{
  color: #7D7D7D;
  font-family: 'Public Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  transition: all 0.5s;

}
.main-container-footer .section-2 ul a:hover{
  color:#25242F;
  transition: all 0.5s;
}
.main-container-footer .con-detail li{
  color: #687362;
  font-family: "public sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  line-height: 0; /* 150% */
  gap: 10px;
  margin-bottom: 22px;
}
.main-container-footer .con-detail li a{
  color: #7D7D7D;
  font-family: "public sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.main-container-footer .email-name{
  color: #7D7D7D;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 24px;
}
.email-input-main input[type="email"]{
  display: flex;
  height: 52px;
  padding: 0px 30px 0px 31px;
  align-items: center;
  width: 400px;
  flex-shrink: 0;
  border-radius: 120px;
  border: 1px solid #D6D6D6;
  background: #FFF;
}
.email-input-main input[type="email"]::placeholder{
  color: #7D7D7D;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.email-input-main input[type="email"]:focus{
  outline: none;
}
.footer-news-letter{
  height: fit-content;
  width: fit-content;
  position: relative;
}
.footer-news-letter .email-input-button{
  position: absolute;
  top: 50%;
  right:  5px;
  cursor: pointer;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #687362;
  gap: 10px;
  color: #FFF;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
  border: 1px solid #687362;
  transition: all 0.5s;
}
.footer-news-letter .email-input-button:hover{
  border: 1px solid #000;
  color: #000;
  transition: all 0.5s;
  background: transparent;
}
.main-container-footer .alright{
  color: #7D7D7D;
  font-family: "Orelega One";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}
.main-container-footer .term{
  color: #7D7D7D;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.social-footer{
  padding-top: 20px;
  display: flex;
  gap: 20px;
}
.footer-logo {
  width: 220px;
}
.main-intro-footer a{
  color: #7D7D7D;
}
@media (max-width:768px){
  .main-container-footer .section-main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 48px;
    border-bottom: 1px solid #D6D6D6;
    padding: 80px;
   
  }
  .main-container-footer .section-main {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 48px;
    border-bottom: 1px solid #D6D6D6;
    padding: 25px 0;
  }
  .container-footer{
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
  }
  .main-container-footer .logo svg{
    width: unset !important;
  }
  .main-intro-footer{
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 24px; /* 150% */
    margin: unset;
    width: 100%;
  } 
  .main-container-footer .section-main2{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 48px;
    border-bottom: 1px solid #D6D6D6;
    padding-bottom: 48px;
    gap: 48px;
  }
  .main-container-footer .section-main2 .links{
    display: flex;
  } 
  .main-container-footer .section-main2 .legal{
    display: flex;
  } 
  .main-container-footer .section-main2 .con-detail{
    display: flex;
  } 
  .main-container-footer .section-main2 .links ul{
    margin: 0;
  }
  .main-container-footer .section-main2 .legal ul{
    margin: 0;
  }
  .main-container-footer .section-main2 .con-detail ul{
    margin: 0;
  }
  .main-container-footer .section-main3{
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px;
    border-bottom: 0px solid #D6D6D6;
    padding-bottom: 60px;
    flex-direction: column;
  }
  
  .main-container-footer .section-2 h5{
      color: #25242F;
      font-family: "Orelega One";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      margin: 0;
      width: 180px;
  }
  .main-container-footer .section-2 ul{
    list-style: none;
    padding-left: 0;
  }
  .main-container-footer .section-2 ul a{
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-decoration: none;
    transition: all 0.5s;
  
  }
  .main-container-footer .section-2 ul a:hover{
    color:#25242F;
    transition: all 0.5s;
  }
  .main-container-footer .con-detail li{
    color: #687362;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    line-height: 0; /* 150% */
    gap: 10px;
    margin-bottom: 22px;
  }
  .main-container-footer .con-detail li a{
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
  .main-container-footer .email-name{
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 24px;
  }
  .email-input-main input[type="email"]{
    display: flex;
    height: 51px;
    padding: 0px 45px 0px 31px;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    border-radius: 120px;
    border: 1px solid #D6D6D6;
    background: #FFF;
  }
  .email-input-main input[type="email"]::placeholder{
    color: #7D7D7D;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .email-input-main input[type="email"]:focus{
    outline: none;
  }
  .footer-news-letter{
    height: fit-content;
    width: fit-content;
    position: relative;
  }
  .footer-news-letter .email-input-button{
    position: absolute;
    top: 50%;
    right: 0;
    left: 81%;
    cursor: pointer;
    transform: translateY(-50%);
    display: inline-flex;
    padding: 9px 58px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #687362;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    border: 1px solid #687362;
    transition: all 0.5s;
  }
  .footer-news-letter .email-input-button:hover{
    border: 1px solid #000;
    color: #000;
    transition: all 0.5s;
    background: transparent;
  }
  .main-container-footer .alright{
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    padding-bottom: 16px;
  }
  .main-container-footer .term{
    color: #7D7D7D;
    font-family: "Orelega One";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height:16px; /* 200% */
  }
  
  
}
@media (min-width:1200px){
  .main-container-footer .section-main2 {
    display: flex;
    justify-content: flex-start;
    gap: 160px;
  }

  .section-1 h2 {
    font-family: "Orelega One", serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: #25242f;
  }

  .footer-contact-input-textarea {
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    width: 968px;
    height: 224px;
    resize: none;
    padding: 20px;
    color: #ccc;
    outline: none;
  }
}

@media (min-width:768px) and (max-width:968px){
  .main-container-footer .section-main2 {
    align-items: flex-start;
    border-bottom: 1px solid #d6d6d6;
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
    padding-bottom: 48px;
    padding-top: 48px;
    width: 85%;
    flex-wrap: wrap;
  }
  .section-1 h2 {
    color: #687362;
    text-align: center;
    font-family: "Orelega One";
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin: 0;
    padding-bottom: 25px;
  }
}

/* desktop view */
@media (min-width:1200px){
  .main-container-footer .section-main{
      width:100%;
  }
  .main-container-footer .section-main .section-1.logo{
      width: 40%;
  }
  .main-container-footer .section-main .section-1.content{
      width: 60%;
  }
  .section-1-footer-contact .content-input-ftr{
      width: 363px !important;
      max-width: 321px;
  }
  
  .container-footer {
      max-width: 1200px;
  }
  .main-container-footer .section-main {
      justify-content: space-between;
  }
  .container-footer .section-1 h2 {
      text-align: left;
      margin-top: 0;
  }
  .details-main-ftr{
    display: flex;
    gap:16px;
  }
  .details-main-ftr .footer-contact-input-textarea {
      height: 30px !important;
      width: 355px !important;
      padding: 7px 20px;
      border-radius: 100px;
      margin-top: 20px;
      max-width: 312px;
      color: #25242f !important;
      font-family: "Public Sans";
  }
  .footer-logo {
    margin-top: -20px;
    width: 220px;
  }
  .social-footer{
    padding-top: 20px;
    display: flex;
    gap: 20px;
  }
}
/* for mobile */
@media (max-width:768px){
  .container-footer {
    max-width: 80%;
}
  .main-container-footer .section-main .section-1.logo{
      width: 93%;
  }
  .main-container-footer .section-main .section-1.content{
      width: 93%;
  }
  .content-input-ftr {
      width: 100% !important;
  }
  .section-1-footer-contact .details-main{
      display: flex;
      gap: 20px;
      flex-direction: column;
  }
  .footer-contact-input-textarea{
    width: 100%;
    height: 24px;
    padding: 10px 3px 10px 23px;
    margin-top: 22px;
    border-radius: 100px;
    border: 1px solid #ccc;
    resize: none;
  }
  .section-1-footer-contact{
    margin-top: 24px;
  }
  .section-1-footer-contact .section-2-contact-button {
    margin-top: 0;
}
.main-container-footer .section-2 h5 {
  color: #25242F;
  font-family: "Orelega One";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  width: 126px;
  padding-right: 12px;
}
.footer-logo {
  height: 47px;
  width: 200px;
  object-fit: contain;
}
}
/* for tab */
@media (min-width:769px) and (max-width:1199px){
  .main-container-footer .section-main .section-1.logo{
      width: 93%;
  }
  .main-container-footer .section-main .section-1.content{
      width: 93%;
  }
  .content-input-ftr {
      width: 100% !important;
  }
  .section-1-footer-contact .details-main{
      display: flex;
      gap: 20px;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
  }
  .section-1-footer-contact .details-sub{
      width: 40%;
  }
}