.new-acct-flex {
  display: flex;
  align-items: flex-start;
}

.new-acct-container {
  width: 100%;
  margin: 0 auto;
  background: #f8f7f2;
  height: 100vh;
  overflow: hidden;
}
.left-new-acct-div,
.right-new-acct-div {
  width: 50%;
}
.right-new-acct-div {
  position: relative;
  background-color: #25242f;
}
.right-new-acct-div img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.7;
}
.left-new-acct-div {
  display: block;
  padding: 67px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: center;
  height: 84vh;
  overflow-y: auto;
}
.left-new-acct-div::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.left-new-acct-div::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.left-new-acct-div::-webkit-scrollbar-thumb
{
	background-color: #AAA;
	border-radius: 10px;
}
.right-new-acct-div .new-acct-banner-abs-text {
  position: absolute;
  z-index: 2;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  width: 500px;
}
.left-new-acct-div .welcome-back-tag {
  width: 100%;
  color: #25242f;
  text-align: center;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 133.333% */
  text-transform: capitalize;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 16px;
}
.left-new-acct-div .welcome-sub-tag {
  color: #7d7d7d;
  width: 100%;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 142.857% */
  margin: 0;
}
.left-new-acct-div form {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #ccc;
  text-align: left;
}

.left-new-acct-div form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.left-new-acct-div form .form-group .input-pw-logo {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  width: 85%;
}

.left-new-acct-div form .form-group-one {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.left-new-acct-div form label {
  color: #7d7d7d;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
}

.left-new-acct-div form .form-group-one label {
  color: #7d7d7d;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
}

.left-new-acct-div form .form-group input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 120px;
  border: none;
  background: #fff;
  width: 100%;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.left-new-acct-div form .form-group .password-svg input::placeholder {
  font-size: 50px;
}



input:focus {
  outline: none;
}

input::placeholder {
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #ccc;
}

.wrp-new-acct {
  position: relative;
  width: 100%;
}
.wrp-new-acct svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.left-new-acct-div .form-group-flx {
  display: flex;
  gap: 8px;
  justify-content: baseline;
  margin-bottom: 40px;
}
.left-new-acct-div .form-group-flx label,
.left-new-acct-div .form-group-flx label a {
  color: #7d7d7d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.left-new-acct-div .new-acct-button {
  display: flex;
  width: 100%;

  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 50px;
  background: #687362;
  margin-top: 40px;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.new-acct-footer p {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.new-acct-footer a {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.form-group-one a {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.first-last-name-main {
  display: flex;
}

.left-new-acct-div form .form-group .input-first-last-name {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  width: 70%;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black;
  transition: background-color 5000s ease-in-out 0s;
}
.new-acct-container .error-text{
  color: red;
  font-size: 14px;
}
.spc-f-g1{
  width: 50%;
}
@media (max-width: 678px) {
  .right-new-acct-div {
    display: none;
  }
  .new-acct-container {
    overflow: unset;
  }
  .left-new-acct-div {
    display: block;
    width: 100%;
    padding: 60px 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    height: max-content;
  }
  .left-new-acct-div .welcome-back-tag {
    width: 100%;
    color: #25242f;
    text-align: center;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: capitalize;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .left-new-acct-div .welcome-sub-tag {
    color: #7d7d7d;
    width: 100%;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0;
  }
  .left-new-acct-div form {
    margin-top: 20px;
    padding-top: 40px;
    border-top: 1px solid #ccc;
  }
  .left-new-acct-div form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .left-new-acct-div form label {
    color: #7d7d7d;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
  }
  .left-new-acct-div form input {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
  }
  .left-new-acct-div .form-group-one input {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 5%;
    margin: 5px 10px 25px 5px;
  }
  .wrp-new-acct {
    position: relative;
    width: 100%;
  }
  .wrp-new-acct svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  .left-new-acct-div .form-group-flx {
    display: flex;
    gap: 8px;
    justify-content: baseline;
    margin-bottom: 40px;
  }
  .left-new-acct-div .form-group-flx label,
  .left-new-acct-div .form-group-flx label a {
    color: #7d7d7d;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .left-new-acct-div .new-acct-button {
    display: flex;
    width: 100%;

    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 50px;
    background: #687362;
    margin-top: 40px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .first-last-name-main {
    display: flex;
    flex-direction: column;
  }
  .left-new-acct-div form .form-group .input-first-last-name {
    display: flex;
    align-items: center;
    padding: 0px 24px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 85%;
  }
  .left-new-acct-div form .form-group .input-pw-logo {
    display: flex;
    align-items: center;
    padding: 0px 24px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 85%;
  }
  .left-new-acct-div form .form-group-one {
    display: flex;
    flex-direction: row;
    margin-bottom: -30px;
  }
  .spc-f-g1{
    width: 100%;
  }
}
