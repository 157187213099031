.UnsubscribePopup-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.star-rating {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.star-rating button {
  background-color: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  padding: 5px;
}

.star-rating .on .star {
  color: #f0cf27;
}

.star-rating .off .star {
  color: #ccc;
}

.question {
  margin: 20px 0;
  text-align: left;
}

.question label {
  display: block;
  margin: 10px 0;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.UnsubscribePopup-no-btn,
.UnsubscribePopup-yes-btn {
  padding: 5px 100px;
  border-radius: 50px;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  font-family: "Orelega One";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
}

.UnsubscribePopup-no-btn {
  background-color: white;
  color: #9d9d9d;
  border: 1px solid #9d9d9d;
}

.UnsubscribePopup-yes-btn {
  background: #687362;
  color: white;
}

.popup-container .UnsubscribePopup-popup-main {
  color: #25242f;
  text-align: center;
  font-family: "Orelega One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.popup-container .UnsubscribePopup-popup-sub {
  color: #7d7d7d;
  text-align: center;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.popup-container .UnsubscribePopup-popup-pickup-main {
  color: #25242f;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.popup-container .UnsubscribePopup-popup-pickup-sub {
  color: #25242f;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.question .UnsubscribePopup-popup-pickup-option {
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.question .UnsubscribePopup-popup-pickup-textarea::placeholder {
  color: #ccc;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

.question .UnsubscribePopup-popup-pickup-option-container {
  display: flex;
  gap: 100px;
}

@media (max-width: 768px) {
  .question .UnsubscribePopup-popup-pickup-option-container {
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }
  .UnsubscribePopup-popup-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 55%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UnsubscribePopup-popup-container {
    position: fixed;
    top: 100px !important;
    left: 16px;
    width: 92%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 236px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .popup {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;

    overflow-y: scroll;
  }
  
}
