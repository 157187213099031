.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 24px 35px 24px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.icon-container {
  margin-bottom: 15px;
}

.card-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #2ecc71;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.popup-container .card-add-popup-main {
  margin-bottom: 10px;
  color: #25242f;
  text-align: center;
  font-family: "Orelega One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
}

.popup-container .card-add-popup-sub {
  margin-bottom: 20px;
  color: #7d7d7d;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.popup-container .ok-button {
  display: flex;
  width: 295px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #28a56e;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
}

.ok-button:hover {
  background-color: #27ae60;
}
.cancle-ok-btn{
  background: #F65C5C;
}
.cancle-ok-btn:hover{
  background-color: #F65C5C;
}
.popup-container .card-remove-popup-main {
  margin-bottom: 10px;
  color: #25242f;
  text-align: center;
  font-family: "Orelega One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
}

.popup-container .card-remove-popup-sub {
  margin-bottom: 20px;
  color: #7d7d7d;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.popup-container .remove-ok-button {
  display: flex;
  width: 295px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #28a56e;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
}

.remove-ok-button:hover {
  background-color: #27ae60;
}

.popup-container .remove-ok-btn{
    display: flex;
    width: 295px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #F65C5C;
    color: #fff;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border: none;
}