.body-main {
    padding-top: 0;
}

.series-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.top-banner-default {
    position: relative;
    width: 100%;
    min-height: 60vh;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.top-banner-default .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.top-banner-default .abs-slider-images {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.top-banner-default .abs-slider-images img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    z-index: 0;
    opacity: 0.7;
}

.bdc-txt {
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
}

.mani-pg-name {
    margin-top: 15px;
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 116.667% */
}

.series-slider-wrapper {
    /* margin-left: -50px; */
}

.slider-set-default {
    margin: 80px 0px 0;
    position: relative;
}

.slider-set-default .slider-set-category {
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    text-transform: capitalize;
    margin-bottom: 55px;
    display: block;
}

.series-slider-home-image {
    width: fit-content;
    border-radius: 12px;
    background: #000;
    border-radius: 12px;
    background: #000;
    position: relative;
}

.series-slider-home-image img, .series-slider-home-image > iframe {
    display: flex;
    width: 320px;
    height: 400px;
    object-fit: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    opacity: 0.7;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.753) 0%, rgb(0, 0, 0) 100%);
    position: relative;
}

.courses-slider-home-image img, .courses-slider-home-image > iframe {
    display: flex;
    width: 320px;
    height: 250px;
    object-fit: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    opacity: 0.7;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.753) 0%, rgb(0, 0, 0) 100%);
    position: relative;
}

.series-slider-content-details {
    position: relative;
}

.series-slider-content-details-topic {
    left: 24px;
    position: relative;
    bottom: 0;
    margin-top: 20px;
    width: 100%;
}

.courses-slider-content-details-topic {
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    top: unset;
    left: unset;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    width: 90%;

}

.series-slider-content-details-topic h3 {
    leading-trim: both;
    text-edge: cap;
    color: #25242f;
    font-family: Orelega One;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    text-transform: capitalize;
    width: unset;
}

.series-slider-content-details-topic p {
    leading-trim: both;
    text-edge: cap;
    color: #25242f;
    font-family: Orelega One;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: 0;
    gap: 6px;
    color: #7D7D7D;
font-size:14px;
}

.courses-slider-content-details-topic h3 {
    color: #25242F;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 222.222% */
    text-transform: capitalize;
}

.courses-slider-content-details-topic p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #9D9D9D;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 285.714% */
    text-transform: capitalize;
    margin: 0;
}

.spc-single-nm {
    color: #25242F;
    leading-trim: both;
    text-edge: cap;
    font-family: "Orelega One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 145.455% */
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 0;
    border-top: 1px solid #CCC;
    width: 90%;
    margin-top: 20px;
    padding-top: 20px;
}

.series-slider-wrapper .slick-prev, .series-slider-wrapper .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: -75px;
    display: block;
    width: 70px !important;
    height: 70px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.series-slider-wrapper .slick-prev {
    left: 1049px !important;
}

.series-slider-wrapper .slick-next {
    right: 0;
}

.adj20 {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    background-color: #000;
}

.adj20 .text {
    z-index: 4;
    text-align: center;
    margin-top: -0px;
}

.adj20 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 450px;
    object-fit: cover;
    opacity: 0.7;
}
.series-main-data h3{
    width: 90%;
}
.slick-initialized .slick-slide {
    margin: 0 auto;
    max-width:100%;
}
.series-slider-home-image {
    padding: 0 6%;
}
@media (max-width: 768px) {
    .top-banner-default {
        position: relative;
        width: 100%;
        min-height: 60vh;
        z-index: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }

    .top-banner-default .text-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 4;
    }

    .top-banner-default .abs-slider-images {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    .top-banner-default .abs-slider-images img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        z-index: 0;
        opacity: 0.7;
    }

    .bdc-txt {
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 112.5% */
    }

    .mani-pg-name {
        margin-top: 10px;
        color: #FFF;
        text-align: center;
        font-family: "Orelega One";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 117.647% */
    }

    .slider-set-default {
        width: 85%;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 0px;
    }

    .series-slider-wrapper {
        margin-left: -13px;
    }

    .series-slider-home-image {
        width: fit-content;
        border-radius: 12px;
        background: #000;
        border-radius: 12px;
        background: #000;
        position: relative;

    }

    .series-slider-home-image img, .series-slider-home-image > iframe {
        width: 260px;
        height: 306px;
        flex-shrink: 0;
        flex-shrink: 0;
        opacity: 0.7;
        border-radius: 12px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.753) 0%, rgb(0, 0, 0) 100%);
        position: relative;
    }

    .courses-slider-home-image img, .courses-slider-home-image > iframe {
        width: 260px;
        height: 150px;
        flex-shrink: 0;
        flex-shrink: 0;
        opacity: 0.7;
        border-radius: 12px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.753) 0%, rgb(0, 0, 0) 100%);
        position: relative;
    }

    .series-slider-content-details {
        position: relative;
    }

    .series-slider-content-details-topic {
        left: 24px;
        position: relative;
        top: 70%;
    }

    .series-slider-content-details-topic h3 {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 120% */
        margin: 0;
        text-transform: capitalize;
    }

    .series-slider-content-details-topic p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #FBF8F3;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 285.714% */
        text-transform: capitalize;
        margin: 0;
    }

    .series-slider-wrapper .slick-prev, .series-slider-wrapper .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: -75px;
        display: block;
        width: 50px !important;
        height: 50px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }

    .series-slider-wrapper .slick-prev {
        left: -13px !important;
        z-index: 99;
    }

    .series-slider-wrapper .slick-next {
        right:-24px;
    }

    .courses-slider-content-details-topic {
        position: relative;
        display: flex;
        gap: 5px;
        align-items: center;
        top: unset;
        left: unset;
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        width: 90%;

    }

    .series-slider-content-details-topic h3 {
        leading-trim: both;
        text-edge: cap;
        color: #25242f;
        font-family: Orelega One;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        text-transform: capitalize;
    }

    .series-slider-content-details-topic p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #FBF8F3;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 285.714% */
        text-transform: capitalize;
        margin: 0;
    }

    .courses-slider-content-details-topic h3, .series-slider-content-details-topic p {
        leading-trim: both;
        text-edge: cap;
        font-family: Orelega One;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        text-transform: capitalize;
        color: #25242f;
    }

    .courses-slider-content-details-topic p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #9D9D9D;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 285.714% */
        text-transform: capitalize;
        margin: 0;
    }

    .spc-single-nm {
        color: #25242F;
        leading-trim: both;
        text-edge: cap;
        font-family: "Orelega One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 145.455% */
        text-transform: capitalize;
        margin: 0;
        margin-bottom: 0;
        margin-bottom: 0;
        border-top: 1px solid #CCC;
        width: 90%;
        margin-top: 20px;
        padding-top: 20px;
    }

    .slider-set-default .slider-set-category {
        width: 71%;
    }
    .series-slider-home-image {
        padding: auto;
    }
}
@media (min-width:768px) and (max-width:1000px){

    .series-slider-wrapper .slick-prev {
        left: 85% !important;
    }
}
@media (min-width:1023px) and (max-width:1199px){
    .series-main-wrapper{
        padding: 30px;
    }
    .series-slider-wrapper .slick-next {
        right: 8%;
    }
    .series-slider-wrapper .slick-prev {
        left: 77% !important;
    }
}
@media (min-width:1024px){
    .series-slider-wrapper .slick-prev {
        left: -104px !important;
    }
    .separate-slider .slick-prev, .separate-slider .slick-next {
        top: 44% !important;
    }
    .series-slider-wrapper .slick-next {
        right: -82px;
    }
}