.body-main-package {
  display: flex;
  align-items: flex-start;
}

.slt-pck-container {
  width: 100%;
  margin: 0 auto;
  background: #f8f7f2;
}
.left-slt-pck-div,
.right-slt-pck-div {
  width: 50%;
}
.right-slt-pck-div {
  position: relative;
  background-color: #25242f;
}

.left-slt-pck-div {
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 60px;
}
.right-slt-pck-div .slt-pck-banner-abs-text {
  position: absolute;
  z-index: 2;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  width: 500px;
}
.left-slt-pck-div .welcome-back-tag {
  width: 100%;
  color: #25242f;
  text-align: center;
  font-family: "Orelega One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 16px;
}
.left-slt-pck-div .welcome-sub-tag {
  color: #7d7d7d;
  width: 100%;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 142.857% */
  margin: 0;
}
.left-slt-pck-div form {
  margin-top: 40px;
  border-top: 1px solid #ccc;
}
.left-slt-pck-div form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.left-slt-pck-div form .form-group .input-pw-logo {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  width: 85%;
}

.left-slt-pck-div form .form-group-one {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.left-slt-pck-div form label {
  color: #7d7d7d;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
}

.left-slt-pck-div form .form-group-one label {
  color: #7d7d7d;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
}

.form-group-one-bottom {
  display: flex;
  margin: 0px -50px;
}

.form-group-one-bottom label {
  color: #7d7d7d;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
}

.left-slt-pck-div form .form-group input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 120px;
  border: none;
  background: #fff;
  width: 100%;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.left-slt-pck-div form .form-group .password-svg input::placeholder {
  font-size: 50px;
}

input:focus {
  outline: none;
}

input::placeholder {
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #ccc;
}

.wrp-slt-pck {
  position: relative;
  width: 100%;
}
.wrp-slt-pck svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.left-slt-pck-div .form-group-flx {
  display: flex;
  gap: 8px;
  justify-content: baseline;
  margin-bottom: 40px;
}
.left-slt-pck-div .form-group-flx label,
.left-slt-pck-div .form-group-flx label a {
  color: #7d7d7d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.left-slt-pck-div .slt-pck-button {
  display: flex;
  width: 100%;

  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 50px;
  background: #687362;
  margin-top: 40px;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.slt-pck-footer p {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.slt-pck-footer a {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.form-group-one a {
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.form-group-one-bottom a {
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7d7d7d;
  text-align: center;
}

.first-last-name-main {
  display: flex;
}

.left-slt-pck-div form .form-group .input-first-last-name {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 120px;
  border: 1px solid #d6d6d6;
  background: #fff;
  width: 70%;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black;
  transition: background-color 5000s ease-in-out 0s;
}
.slt-pck-container .header-topic-main {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.select-package-price-card .section-2-price-details h1 {
  display: none;
}

.select-package-price-card .section-2-price-details p {
  display: none;
}
.select-pkg-logo-main img{
  width: 220px;
}
@media (max-width: 678px) {
  .right-slt-pck-div {
    display: none;
  }
  .left-slt-pck-div {
    display: flex;
    width: 100%;
    padding: 60px 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
  }
  .left-slt-pck-div .welcome-back-tag {
    width: 100%;
    color: #25242f;
    text-align: center;
    font-family: "Orelega One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: capitalize;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .left-slt-pck-div .welcome-sub-tag {
    color: #7d7d7d;
    width: 100%;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 60px 0px;
  }
  .left-slt-pck-div form {
    margin-top: 20px;
    padding-top: 40px;
    border-top: 1px solid #ccc;
  }
  .left-slt-pck-div form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .left-slt-pck-div form label {
    color: #7d7d7d;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
  }
  .left-slt-pck-div form input {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
  }
  .left-slt-pck-div .form-group-one input {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 5%;
    margin: 5px 10px 25px 5px;
  }
  .wrp-slt-pck {
    position: relative;
    width: 100%;
  }
  .wrp-slt-pck svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  .left-slt-pck-div .form-group-flx {
    display: flex;
    gap: 8px;
    justify-content: baseline;
    margin-bottom: 40px;
  }
  .left-slt-pck-div .form-group-flx label,
  .left-slt-pck-div .form-group-flx label a {
    color: #7d7d7d;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .left-slt-pck-div .slt-pck-button {
    display: flex;
    width: 100%;

    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 50px;
    background: #687362;
    margin-top: 40px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .first-last-name-main {
    display: flex;
    flex-direction: column;
  }
  .left-slt-pck-div form .form-group .input-first-last-name {
    display: flex;
    align-items: center;
    padding: 0px 24px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 85%;
  }
  .left-slt-pck-div form .form-group .input-pw-logo {
    display: flex;
    align-items: center;
    padding: 0px 24px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 120px;
    border: 1px solid #d6d6d6;
    background: #fff;
    width: 85%;
  }
  .left-slt-pck-div form .form-group-one {
    display: flex;
    flex-direction: row;
    margin-bottom: -30px;
  }
  .left-slt-pck-div,
  .right-slt-pck-div {
    width: 100%;
  }
  .left-slt-pck-div {
    display: block;
    padding: 60px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 60px;
  }
  .form-group-one-bottom {
    display: flex;
    margin: 0px 0px;
    padding: 60px 24px;
  }
  .select-pkg-logo-main{
    display: flex;
    justify-content: center;
  }
}
