/* Setup header */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #687362;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000000;
  padding: 0px;
  padding-bottom: 10px;
  padding-top: 48px;
  transition: padding-top 0.3s ease; /* Smooth transition */
}
.dropdown-toggle-new {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdown-menu2 {
  position: absolute;
  top: 54px;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 4px;
  width: max-content;
  display: flex;
  flex-direction: column;
  background: white;
  gap: 15px;
  min-width: 104px;
}
.dropdown-item {
  text-decoration: none;
  color: #000;
}

.yellow-class {
  z-index: 999999999;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 48px;
  background: #cfc8a8;
  width: 100%;
  display: flex;
  height: 40px;
  padding: 0px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #25242f;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 114.286% */
  gap: 5px;
}
.yellow-class a {
  text-decoration: none;
  color: #25242f;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.header-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
}
header .logo-box {
  position: relative;
  margin-left: 0;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.7rem;
}

.logo-box .logo {
  border-radius: 10px;
  display: grid;
  margin-left: 0;
  place-items: center;
}

.logo .logo-letters {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
}

.logo-box .logo-text {
  font-size: 20px;
  color: var(--text);
}

header nav {
  position: relative;
  margin-right: 0rem;
  width: auto;
  height: auto;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

nav .header-link {
  padding: 0px 12px;
  color: #fff !important;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration: none;
}

nav .header-link:hover {
  transition: 0.3s ease;
  cursor: pointer;
}

.menu-button,
.sidebar {
  display: none;
}
nav .login-signup {
  display: flex;
  width: auto;
  padding: 3px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #fff;
  color: #000 !important;
}

/* to kawushalya */
.login-signup .login-signup-photo-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 150px;
  color: #fff !important;
  font-family: "Orelega One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}
.login-signup .login-signup-photo-name img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  object-fit: cover;
}
.no-background {
  background: transparent !important;
}

.login-signup .dropdown-menu {
  position: absolute;
  top: 66px;
  left: unset;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 4px;
  width: max-content;
}

.login-signup .dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
}

.login-signup .dropdown-item:hover {
  background-color: #f1f1f1;
}
.login-signup .login-signup-photo-name .down-ico-hdr {
  height: 16px;
  width: 16px;
  object-fit: contain;
}
@media screen and (max-width: 1200px) {
  header .logo-box {
    margin-left: 30px;
  }
  header nav {
    margin-right: 0rem;
  }
}
.logo img {
  width: 220px;
}
@media screen and (max-width: 750px) {
  header {
    position: fixed !important;
  }
  .logo-box .logo-text {
    font-size: 18px;
  }

  header nav {
    display: none;
  }
  .logo svg {
    width: auto !important;
  }
  .menu-button {
    display: block;
    position: relative;
    margin-right: 1rem;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 24px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
  }

  .menu-button:hover {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: transparent;
  }

  .menu-button:focus {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: transparent;
  }

  .sidebar {
    position: fixed;
    right: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #687362;
    box-shadow: -3px 0 15px rgba(0, 0, 0, 0.15);
    z-index: 1100000;
    text-align: center;
    /* animation: sidebarIn 1s 0s forwards; */
    top: 0;
    z-index: 99999999;
  }
  .bi-x-lg::before {
    content: "\f659";
    color: #fff;
  }
  header {
    position: fixed !important;
    padding: 10px 25px;
    padding-left: 5px;
    padding-top: 65px;
  }
  @keyframes sidebarIn {
    0% {
      opacity: 1;
      transform: translateX(25rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes sidebarOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateX(30rem);
    }
  }

  .sidebar .sidebar-header {
    position: relative;
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar-header .sidebar-title {
    position: relative;
    margin-left: 1rem;
    font-size: 22px;
    font-weight: 600;
  }

  .sidebar-header .close-button {
    position: relative;
    margin-right: 1rem;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
  }

  .close-button:hover {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #ffffff00;
  }

  .close-button:focus {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #ffffff00;
  }

  .sidebar .sidebar-link {
    position: relative;
    padding: 16px 0 16px 1rem;
    font-size: 18px;
    border-bottom: 1px solid #fff;
    color: #000;
    text-decoration: none;
  }

  .sidebar .sidebar-link:hover {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #fff;
  }

  .sidebar .sidebar-link:focus {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #fff;
  }
  .bi-list::before {
    content: "\f479";
    color: #fff;
  }
  .sidebar-header .sidebar-title {
    position: relative;
    margin-left: 1rem;
    font-size: 22px;
    font-weight: 600;
    visibility: hidden;
  }
  .mobile-item-menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-top: 36px;
    padding-bottom: 36px;
    width: 85%;
    margin: 0 auto;
  }
  .mobile-item-menu .header-link {
    color: #cfc8a8 !important;
    font-family: "Orelega One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    text-decoration: none;
  }
  .mobile-item-menu .header-link:hover {
    color: #fff !important;
    position: relative;
  }
  .mobile-item-menu .header-link:hover .header-link:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 5px;
  }
  .login-sgn-mobile {
    width: 85%;
    margin: 0 auto;
    margin-top: 35px;
    color: #cfc8a8 !important;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    display: flex;
    justify-content: center;
  }
  .login-sgn-mobile a {
    color: #fff !important;
    text-align: center;
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration: none;
  }
  .logo-box .logo {
    display: grid;
    place-items: center;
    border-radius: 10px;
    margin-left: 0px;
  }
  .menu-button {
    display: block;
    position: relative;
    margin-right: 25px;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 24px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
  }
  .logo-box .logo {
    display: grid;
    place-items: center;
    border-radius: 10px;
    margin-left: 0px;
  }
  .yellow-class {
    z-index: 9999999;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    background: #cfc8a8;
    width: 100%;
    display: inline-block;
    height: auto;
    padding: 15px 0px 15px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #25242f;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    gap: 5px;
  }
  .yellow-class a {
    text-decoration: none;
    color: #25242f;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 130% */
  }
  .login-sgn-mobile .login-signup-photo-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 150px;
    color: #cfc8a8 !important;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
  }
  .login-sgn-mobile img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
  }
  .no-background {
    background: transparent !important;
  }

  .login-sgn-mobile {
    position: relative;
  }

  .login-sgn-mobile .dropdown-menu {
    position: absolute;
    top: 66px;
    left: unset;
    right: 50%;
    transform: translateX(50%);
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 4px;
    width: max-content;
  }

  .login-sgn-mobile .dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    border: none;
    text-align: left;
    width: 100%;
  }

  .login-sgn-mobile .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  .logo img {
    height: 44px;
    width: 200px;
    object-fit: contain;
  }
  .login-sgn-mobile {
    width: 100%;
    margin: 0 auto;
    margin-top: 35px;
    color: #cfc8a8 !important;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .login-sgn-mobile .login-signup-photo-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: fit-content;
    color: #cfc8a8 !important;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    width: 100%;
    justify-content: center;
  }
  .login-signup-photo-name svg {
    position: unset;
    right: 0;
  }
  .dropdown-menu2 {
    position: absolute;
    top: 145px;
    left: 32%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 4px;
    width: max-content;
    display: flex;
    flex-direction: column;
    background: white;
    gap: 15px;
    min-width: 104px;
  }
  .dropdown-toggle-new {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    justify-content: center;
    margin-left: 15px;
  }
}

/* tab header */

@media (min-width: 751px) and (max-width: 1024px) {
  header {
    position: fixed !important;
  }
  .logo-box .logo-text {
    font-size: 18px;
  }

  header nav {
    display: none;
  }
  .logo svg {
    width: auto !important;
  }
  .menu-button {
    display: block;
    position: relative;
    margin-right: 1rem;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 24px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
  }

  .menu-button:hover {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: transparent;
  }

  .menu-button:focus {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: transparent;
  }

  .sidebar {
    position: fixed;
    right: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #687362;
    box-shadow: -3px 0 15px rgba(0, 0, 0, 0.15);
    z-index: 1100000;
    text-align: center;
    /* animation: sidebarIn 1s 0s forwards; */
    top: 0;
    z-index: 99999999;
  }
  .bi-x-lg::before {
    content: "\f659";
    color: #fff;
  }
  header {
    position: fixed !important;
    padding: 10px 25px;
    padding-left: 5px;
    padding-top: 65px;
  }
  @keyframes sidebarIn {
    0% {
      opacity: 1;
      transform: translateX(25rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes sidebarOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateX(30rem);
    }
  }

  .sidebar .sidebar-header {
    position: relative;
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar-header .sidebar-title {
    position: relative;
    margin-left: 1rem;
    font-size: 22px;
    font-weight: 600;
  }

  .sidebar-header .close-button {
    position: relative;
    margin-right: 1rem;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
  }

  .close-button:hover {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #ffffff00;
  }

  .close-button:focus {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #ffffff00;
  }

  .sidebar .sidebar-link {
    position: relative;
    padding: 16px 0 16px 1rem;
    font-size: 18px;
    border-bottom: 1px solid #fff;
    color: #000;
    text-decoration: none;
  }

  .sidebar .sidebar-link:hover {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #fff;
  }

  .sidebar .sidebar-link:focus {
    transition: 0.3s ease;
    cursor: pointer;
    background-color: #fff;
  }
  .bi-list::before {
    content: "\f479";
    color: #fff;
  }
  .sidebar-header .sidebar-title {
    position: relative;
    margin-left: 1rem;
    font-size: 22px;
    font-weight: 600;
    visibility: hidden;
  }
  .mobile-item-menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-top: 36px;
    padding-bottom: 36px;
    width: 85%;
    margin: 0 auto;
  }
  .mobile-item-menu .header-link {
    color: #cfc8a8 !important;
    font-family: "Orelega One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    text-decoration: none;
  }
  .mobile-item-menu .header-link:hover {
    color: #fff !important;
    position: relative;
  }
  .mobile-item-menu .header-link:hover .header-link:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 5px;
  }
  .login-sgn-mobile {
    width: 85%;
    margin: 0 auto;
    margin-top: 35px;
    color: #cfc8a8 !important;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    display: flex;
    justify-content: center;
  }
  .login-sgn-mobile a {
    color: #fff !important;
    text-align: center;
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration: none;
  }
  .logo-box .logo {
    display: grid;
    place-items: center;
    border-radius: 10px;
    margin-left: 0px;
  }
  .menu-button {
    display: block;
    position: relative;
    margin-right: 25px;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 24px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
  }
  .logo-box .logo {
    display: grid;
    place-items: center;
    border-radius: 10px;
    margin-left: 0px;
  }
  .yellow-class {
    z-index: 9999999;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    background: #cfc8a8;
    width: 100%;
    display: inline-block;
    height: auto;
    padding: 15px 0px 15px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #25242f;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    gap: 5px;
  }
  .yellow-class a {
    text-decoration: none;
    color: #25242f;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 130% */
  }
  .login-sgn-mobile .login-signup-photo-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 150px;
    color: #cfc8a8 !important;
    font-family: "Orelega One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
  }
  .login-sgn-mobile img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
  }
  .no-background {
    background: transparent !important;
  }

  .login-sgn-mobile {
    position: relative;
  }

  .login-sgn-mobile .dropdown-menu {
    position: absolute;
    top: 66px;
    left: unset;
    right: 50%;
    transform: translateX(50%);
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 4px;
    width: max-content;
  }

  .login-sgn-mobile .dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    border: none;
    text-align: left;
    width: 100%;
  }

  .login-sgn-mobile .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  header nav {
    margin-right: 0;
  }
  header {
    padding: 65px 0px 15px 0;
    position: fixed !important;
  }
  .header-container {
    display: flex;
    width: 93%;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
  }
  .menu-button {
    margin-right: 0;
  }
}

.yellow-class {
  position: fixed !important;
}