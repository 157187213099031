.section {
    padding: 24px;
}

.legal-title {
    color: #25242F;
    text-align: center;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    text-transform: capitalize;
}

.legal-info {
    color: #7D7D7D;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
}

.legal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.legal-special-color .faq-item .faq-question {
    padding: 40px 0px;
    border-top: 1px solid #25242F;
    border-bottom: 0px solid #25242F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #25242F;
    font-family: "Orelega One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    text-transform: capitalize;
    cursor: pointer;
}

.legal-special-color .faq-item:last-child .faq-question {
    padding: 40px 0px;
    border-top: 1px solid #25242F;
    border-bottom: 0px solid #25242F;
}

.legal-special-color .faq-item .faq-answer {
    color: #25242F;
}

.legal-special-bg {
    background: #fff;
}

@media (min-width: 1200px) {
    .legal-info {
        color: #7D7D7D;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 730px;
    }

    .section {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
}
